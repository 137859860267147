import { Button, Input, Table, Tag } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import { TableProps } from 'antd/lib';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Head } from '../../../Component/Head/Head';
import Icon from '../../../Component/Icon/Icon';
import { Main } from '../../../Component/Main/Main';
import { Side } from '../../../Component/Side/Side';
import { GetPropertiesListResponseDto, PropertiesListPropertyDto } from '../../../Dto/Response/GetPropertiesListReponseDto';
import useDebounce from '../../../Hook/useDebounce';
import useNumberFormat from '../../../Hook/useNumberFormat';
import useTranslate from '../../../Hook/useTranslate';
import { TableSorter } from '../../../Interface/TableSorter';
import * as apiService from '../../../Service/ApiService';

const PropertyManage = (): JSX.Element => {
	const { translate } = useTranslate();
	const { format } = useNumberFormat();
	const navigate = useNavigate();

	const [isFetchingPropertiesList, setIsFetchingPropertiesList] = useState<boolean>(false);

	const [properties, setProperties] = useState<PropertiesListPropertyDto[]>([]);
	const [query, setQuery] = useState<string>('');
	const debounceQuery = useDebounce<string>(query, 500);

	const [currentPage, setCurrentPage] = useState<number>(1);
	const [pageSize] = useState<number>(10);
	const [total, setTotal] = useState<number>(0);
	const [tableSort, setTableSort] = useState<TableSorter>({
		field: 'updatedAt',
		order: 'descend',
	});

	useEffect(() => {
		const getPropertiesList = async (): Promise<void> => {
			setIsFetchingPropertiesList(true);

			try {
				const result = await apiService.makeRequest<GetPropertiesListResponseDto>({
					route: 'property/list',
					method: 'POST',
					data: {
						query: debounceQuery,
						currentPage: currentPage,
						pageSize: pageSize,
						sortField: tableSort.field,
						sortOrder: tableSort.order,
					},
				});

				setProperties(result.properties);
				setTotal(result.total);
			} catch (error) {
				console.error(error);
			} finally {
				setIsFetchingPropertiesList(false);
			}
		};

		getPropertiesList();
	}, [debounceQuery, currentPage, pageSize, tableSort]);

	const handlePageChange = (newPage: number): void => {
		if (newPage > 0 && newPage <= Math.ceil(total / pageSize)) {
			setCurrentPage(newPage);
		}
	};

	const handleTableChange: TableProps['onChange'] = (_, __, sorter) => {
		const { columnKey, order } = sorter as SorterResult<string>;

		setTableSort({
			field: Array.isArray(columnKey) ? columnKey[0] : columnKey,
			order: order,
		});
	};

	return (
		<React.Fragment>
			<Main>
				<Head
					title={translate('property.manage.title')}
					subtitle={translate('property.manage.subtitle')}
					// TODO Feature flag create property
					//createUrl='/property/create'
					createIcon='faPlus'
				/>

				<Table
					dataSource={properties}
					columns={[
						{
							title: translate('property.manage.table.name'),
							dataIndex: 'name',
							key: 'name',
							sorter: true,
						},
						{
							title: translate('property.manage.table.type'),
							dataIndex: 'type',
							key: 'type',
							align: 'center',
							sorter: true,
						},
						{
							title: translate('property.manage.table.address'),
							dataIndex: 'city',
							key: 'city',
							sorter: true,
							render: (_, record: PropertiesListPropertyDto) => (
								<span>
									{record.city}, {record.postalCode}
								</span>
							),
						},
						{
							title: translate('property.manage.table.price'),
							dataIndex: 'fullPrice',
							key: 'fullPrice',
							width: '100px',
							align: 'center',
							sorter: true,
							render: (_, record: PropertiesListPropertyDto): string => {
								if (!record.fullPrice) return null;
								return `${format(record.fullPrice)} €`;
							},
						},
						{
							title: translate('property.manage.table.status'),
							dataIndex: 'status',
							key: 'status',
							align: 'center',
							sorter: true,
							render: (_, record: PropertiesListPropertyDto): JSX.Element => {
								let statusColor = 'processing';

								switch (`${record.status}`) {
									case '1':
									case '3':
									case '4':
										statusColor = 'orange';
										break;
									case '2':
										statusColor = 'processing';
										break;

									case '5':
									case '6':
										statusColor = 'success';
										break;

									default:
										statusColor = 'processing';
										break;
								}

								return (
									<Tag bordered={false} color={statusColor}>
										{record.statusName}
									</Tag>
								);
							},
						},
						{
							title: translate('property.manage.table.updated_at'),
							dataIndex: 'updatedAt',
							key: 'updatedAt',
							sorter: true,
							render: (_, record: PropertiesListPropertyDto): JSX.Element => (
								<span>{dayjs(record.updatedAt).format('L LTS')}</span>
							),
						},
						{
							title: translate('property.manage.table.action'),
							key: 'action',
							width: '0px',
							fixed: 'right',
							render: (_, record: PropertiesListPropertyDto): JSX.Element => (
								<Button type='text' onClick={(): void => navigate(`/property/edit/${record.uuid}`)}>
									<Icon icon='faPen' color='#71717A' />
								</Button>
							),
						},
					]}
					rowKey='uuid'
					loading={isFetchingPropertiesList}
					size='middle'
					bordered
					scroll={{ x: true }}
					onChange={handleTableChange}
					pagination={{
						showSizeChanger: false,
						current: currentPage,
						pageSize: pageSize,
						total: total,
						showTotal: (total) => (
							<Tag bordered={false} color='processing'>
								{translate('table.total', { total: total })}
							</Tag>
						),
						onChange: handlePageChange,
						size: 'default',
					}}
				/>
			</Main>

			<Side>
				<Head title={translate('filters')} titleSize='18px' titleWeight='500' />

				<Input
					onChange={(event: React.ChangeEvent<HTMLInputElement>): void => setQuery(event.target.value)}
					prefix={<Icon icon='faSearch' color='#3F3F46' />}
					placeholder={translate('search')}
					size='large'
				/>
			</Side>
		</React.Fragment>
	);
};

export default PropertyManage;
