import type { DropAnimation } from '@dnd-kit/core';
import { DragOverlay, defaultDropAnimationSideEffects } from '@dnd-kit/core';
import type { PropsWithChildren } from 'react';

const dropAnimationConfig: DropAnimation = {
	//duration: 100,
	sideEffects: defaultDropAnimationSideEffects({
		styles: {
			active: {
				opacity: '0.4',
			},
		},
	}),
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

export const SortableOverlay = ({ children }: PropsWithChildren<Props>): JSX.Element => {
	return <DragOverlay dropAnimation={dropAnimationConfig}>{children}</DragOverlay>;
};
