import { Button, Input, Spin, Table, Tag, notification } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import { TableProps } from 'antd/lib';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Head } from '../../../Component/Head/Head';
import Icon from '../../../Component/Icon/Icon';
import { Main } from '../../../Component/Main/Main';
import { Side } from '../../../Component/Side/Side';
import SessionContext from '../../../Context/SessionContext';
import { GetTeamsListResponseDto, TeamListDto } from '../../../Dto/Response/GetTeamsListResponseDto';
import useDebounce from '../../../Hook/useDebounce';
import useTranslate from '../../../Hook/useTranslate';
import { TableSorter } from '../../../Interface/TableSorter';
import * as apiService from '../../../Service/ApiService';

const TeamManage = () => {
	const { translate } = useTranslate();
	const navigate = useNavigate();
	const { logoutUser } = useContext(SessionContext);
	const [notificationApi, notificationApiContextHolder] = notification.useNotification();

	const [isFetchingTeams, setIsFetchingTeams] = useState<boolean>(false);

	const [teams, setTeams] = useState<TeamListDto[]>([]);
	const [query, setQuery] = useState<string>('');
	const debounceQuery = useDebounce<string>(query, 500);

	const [currentPage, setCurrentPage] = useState<number>(1);
	const [pageSize] = useState<number>(5);
	const [total, setTotal] = useState<number>(0);
	const [tableSort, setTableSort] = useState<TableSorter>({
		field: 'updatedAt',
		order: 'descend',
	});

	useEffect(() => {
		const getTeamsList = async (): Promise<void> => {
			setIsFetchingTeams(true);

			try {
				const result = await apiService.makeRequest<GetTeamsListResponseDto>(
					{
						route: 'team/list',
						method: 'POST',
						data: {
							query: debounceQuery,
							currentPage: currentPage,
							pageSize: pageSize,
							sortField: tableSort.field,
							sortOrder: tableSort.order,
						},
					},
					//logoutUser
				);

				setTeams(result.teams);
				setTotal(result.total);
			} catch (error) {
				notificationApi.error({
					message: translate('error'),
					description: `${error}`,
				});

				console.error(error);
			} finally {
				setIsFetchingTeams(false);
			}
		};

		getTeamsList();
	}, [debounceQuery, currentPage, pageSize, tableSort]);

	const handlePageChange = (newPage: number): void => {
		if (newPage > 0 && newPage <= Math.ceil(total / pageSize)) {
			setCurrentPage(newPage);
		}
	};

	const handleTableChange: TableProps['onChange'] = (_, __, sorter): void => {
		const { columnKey, order } = sorter as SorterResult<string>;

		setTableSort({
			field: Array.isArray(columnKey) ? columnKey[0] : columnKey,
			order: order,
		});
	};

	return (
		<React.Fragment>
			{notificationApiContextHolder}

			<Main>
				<Spin spinning={isFetchingTeams}>
					<Head
						title={translate('team.manage.title')}
						subtitle={translate('team.manage.subtitle')}
						createUrl='/admin/team/create'
						createIcon='faPlus'
					/>

					<Table
						dataSource={teams}
						columns={[
							{
								title: translate('team.manage.table.name'),
								dataIndex: 'name',
								key: 'team.name',
								sorter: true,
							},
							{
								title: translate('team.manage.table.members'),
								dataIndex: 'userCount',
								key: 'userCount',
								align: 'center',
								sorter: true,
								render: (_, record: any) => (
									<Tag bordered={false} color='processing'>
										{record.userCount}
									</Tag>
								),
							},
							{
								title: translate('team.manage.table.roles'),
								dataIndex: 'roleCount',
								key: 'roleCount',
								align: 'center',
								sorter: true,
								render: (_, record: any) => (
									<Tag bordered={false} color='processing'>
										{record.roleCount}
									</Tag>
								),
							},
							{
								title: translate('team.manage.table.updated_at'),
								dataIndex: 'updatedAt',
								key: 'updatedAt',
								sorter: true,
								defaultSortOrder: 'descend',
								render: (_, record: any) => <span>{dayjs(record.updatedAt).format('L LTS')}</span>,
							},
							{
								title: translate('team.manage.table.action'),
								key: 'action',
								width: '0px',
								fixed: 'right',
								render: (_, record: any) => (
									<Button type='text' onClick={() => navigate(`/admin/team/edit/${record.uuid}`)}>
										<Icon icon='faPen' color='#71717A' />
									</Button>
								),
							},
						]}
						rowKey='uuid'
						size='middle'
						bordered
						scroll={{ x: true }}
						onChange={handleTableChange}
						pagination={{
							showSizeChanger: false,
							current: currentPage,
							pageSize: pageSize,
							total: total,
							showTotal: (total) => (
								<Tag bordered={false} color='processing'>
									{translate('table.total', { total: total })}
								</Tag>
							),
							onChange: handlePageChange,
							size: 'default',
						}}
					/>
				</Spin>
			</Main>

			<Side>
				<Head title={translate('filters')} titleSize='18px' titleWeight='500' />

				<Input
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => setQuery(event.target.value)}
					prefix={<Icon icon='faSearch' color='#3F3F46' />}
					placeholder={translate('search')}
					size='large'
				/>
			</Side>
		</React.Fragment>
	);
};

export default TeamManage;
