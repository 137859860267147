import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { IconProps } from './_Prop/IconProps';

const Icon = ({
	icon,
	color = '#000000',
	size = undefined,
	bounce = false,
	spin = false,
	spinPulse = false,
	shake = false,
}: IconProps): JSX.Element => {
	const [definitions, setDefinitions] = useState<IconProp | null>(null);

	useEffect(() => {
		if (icon) {
			import(`@fortawesome/free-solid-svg-icons`).then((module) => {
				setDefinitions(module[icon] as IconProp);
			});
		}
	}, [icon]);

	if (definitions) {
		return (
			<FontAwesomeIcon
				icon={definitions}
				color={color}
				fontSize={size}
				bounce={bounce}
				spin={spin}
				spinPulse={spinPulse}
				shake={shake}
			/>
		);
	} else if (icon !== null) {
		return <FontAwesomeIcon opacity={0} icon={faCircle} fontSize={size} />;
	}

	return <></>;
};

export default Icon;
