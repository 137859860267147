import { Button, Form, Input, Spin, message } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import Icon from '../Component/Icon/Icon';
import SessionContext from '../Context/SessionContext';
import { LoginResponseDto } from '../Dto/Response/LoginResponseDto';
import useTranslate from '../Hook/useTranslate';
import * as apiService from '../Service/ApiService';

interface FormValues {
	username: string;
	password: string;
}

const AuthenticationLayout = (): JSX.Element => {
	const navigate: NavigateFunction = useNavigate();
	const { translate } = useTranslate();
	const { user, loginUser } = useContext(SessionContext);
	const [loginForm] = Form.useForm();
	const [messageApi, messageApiContextHolder] = message.useMessage();

	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

	useEffect(() => {
		if (user) navigate('/property/manage');
	}, [user, navigate]);

	const submit = async (values: FormValues): Promise<void> => {
		try {
			setIsSubmitting(true);

			const result = await apiService.makeRequest<LoginResponseDto>({
				route: 'authentication/login',
				method: 'POST',
				data: {
					...values,
				},
			});

			loginUser(result.token, result.uuid, result.lastname, result.firstname, result.email, result.globalRole, result.teams);

			setIsSubmitting(false);

			navigate('/property/manage');
		} catch (error) {
			setIsSubmitting(false);

			messageApi.open({
				type: 'error',
				content: translate('authentication.error'),
			});
		}
	};

	return (
		<React.Fragment>
			{messageApiContextHolder}

			<Spin spinning={isSubmitting} fullscreen />

			<div className='flex justify-center items-center h-screen'>
				<Form
					form={loginForm}
					onFinish={submit}
					layout='vertical'
					className='flex flex-col w-[344px] bg-white shadow-immerp rounded-xl px-10 py-8 gap-10'
				>
					<div className='flex flex-col gap-2'>
						<Form.Item name='email' label={translate('authentication.email')}>
							<Input suffix={<Icon icon='faUser' color='#A1A1AA' />} size='large' type='text' autoComplete='username' />
						</Form.Item>

						<Form.Item name='password' label={translate('authentication.password')}>
							<Input.Password size='large' type='password' autoComplete='current-password' />
						</Form.Item>
					</div>

					<Form.Item>
						<Button disabled={isSubmitting} size='large' block type='primary' htmlType='submit'>
							{translate('authentication.login')}
						</Button>
					</Form.Item>
				</Form>
			</div>
		</React.Fragment>
	);
};

export default AuthenticationLayout;
