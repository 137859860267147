import React, { useEffect, useState } from 'react';
import { useCollapse } from 'react-collapsed';
import { Link, useLocation } from 'react-router-dom';
import useTranslate from '../../../Hook/useTranslate';
import Icon from '../../Icon/Icon';
import { ItemType } from '../_Type/ItemType';

const MenuItem = ({ label, id, link, icon, childrens, isChildren = false }: ItemType): JSX.Element => {
	const { translate } = useTranslate();
	const location = useLocation();

	const [isExpanded, setExpanded] = useState(false);
	const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });
	const [isActive, setIsActive] = useState(false);

	const handleExpanded = (): void => {
		if (!childrens) return;

		setExpanded(!isExpanded);
	};

	useEffect(() => {
		let childrenIsActive: boolean = false;

		if (childrens) {
			for (const children of childrens) {
				if (location.pathname === children.link) {
					childrenIsActive = true;
					break;
				}
			}
		}

		if (location.pathname === link || childrenIsActive) {
			setIsActive(true);
		} else {
			setIsActive(false);
		}

		if (childrenIsActive) {
			setExpanded(true);
		}
	}, [link, childrens, location]);

	const menuItemContent = (
		<div
			{...getToggleProps({ onClick: handleExpanded })}
			className={`flex justify-between items-center w-full box-border py-2 px-4 rounded-md cursor-pointer ${
				isActive && !isChildren ? 'bg-[#EEF2FF]' : ''
			} hover:bg-[#EEF2FF]`}
		>
			<div className='flex items-center gap-[10px]'>
				{icon && (
					<div className='flex justify-center items-center rounded-lg h-[28px] w-[28px] bg-[#EEF2FF]'>
						<Icon icon={icon} size={15} color='#5078FD' />
					</div>
				)}

				{!icon && (
					<React.Fragment>
						{isActive && (
							<div className='relative flex justify-center items-center rounded-lg h-[28px] w-[28px]'>
								<div className='absolute flex h-[14px] w-[14px] rounded-full bg-[#CED9FE]'></div>
								<div className='absolute flex h-[6px] w-[6px] rounded-full bg-[#5078FD]'></div>
							</div>
						)}
						{!isActive && (
							<div className='flex justify-center items-center rounded-lg h-[28px] w-[28px]'>
								<div className='flex h-[6px] w-[6px] rounded-full bg-[#A1A1AA]'></div>
							</div>
						)}
					</React.Fragment>
				)}

				<div className={`${!isChildren ? 'text-black' : isActive && isChildren ? 'text-black' : 'text-[#71717A]'}`}>
					{translate(label)}
				</div>
			</div>

			{childrens && <Icon icon={isExpanded ? 'faChevronDown' : 'faChevronRight'} />}
		</div>
	);

	const menuItemLink =
		link && !isActive ? (
			<Link to={link} className='text-black no-underline w-full select-none'>
				{menuItemContent}
			</Link>
		) : (
			<div className='text-black no-underline w-full select-none'>{menuItemContent}</div>
		);

	return (
		<div className='flex flex-col items-center gap-1'>
			{menuItemLink}

			{childrens && (
				<div {...getCollapseProps()} className={`flex flex-col w-full`}>
					{childrens.map((child) => (
						<MenuItem
							key={child.id}
							label={child.label}
							id={child.id}
							link={child.link}
							icon={child.icon}
							childrens={child.childrens}
							isChildren={true}
						/>
					))}
				</div>
			)}
		</div>
	);
};

export default MenuItem;
