import { Button, Spin, Table, TableProps, Tag, notification } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Head } from '../../../Component/Head/Head';
import Icon from '../../../Component/Icon/Icon';
import {
	CombinedCharacteristicListDto,
	GetCombinedsCharacteristicListResponseDto,
} from '../../../Dto/Response/GetCombinedsCharacteristicListResponseDto';
import useTranslate from '../../../Hook/useTranslate';
import { TableSorter } from '../../../Interface/TableSorter';
import * as apiService from '../../../Service/ApiService';

const CharacteristicManage = (): JSX.Element => {
	const { translate } = useTranslate();
	const navigate = useNavigate();
	const [notificationApi, notificationApiContextHolder] = notification.useNotification();

	const [isFetchingGroupsCharacteristic, setIsFetchingGroupsCharacteristic] = useState<boolean>(false);

	const [characteristics, setCharacteristics] = useState<CombinedCharacteristicListDto[]>([]);

	const [currentPage, setCurrentPage] = useState<number>(1);
	const [pageSize] = useState<number>(10);
	const [total, setTotal] = useState<number>(0);
	const [tableSort, setTableSort] = useState<TableSorter>({
		field: 'updatedAt',
		order: 'descend',
	});

	useEffect(() => {
		const getGroupsCharacteristicList = async (): Promise<void> => {
			setIsFetchingGroupsCharacteristic(true);

			try {
				const result = await apiService.makeRequest<GetCombinedsCharacteristicListResponseDto>({
					route: 'characteristic/combined-list',
					method: 'POST',
					data: {
						query: '',
						currentPage: currentPage,
						pageSize: pageSize,
						sortField: tableSort.field,
						sortOrder: tableSort.order,
					},
				});

				setCharacteristics(result.combinedsCharacteristic);
				setTotal(result.total);
			} catch (error) {
				notificationApi.error({
					message: translate('error'),
					description: `${error}`,
				});

				console.error(error);
			} finally {
				setIsFetchingGroupsCharacteristic(false);
			}
		};

		getGroupsCharacteristicList();
	}, [currentPage, pageSize, tableSort]);

	const handlePageChange = (newPage: number): void => {
		if (newPage > 0 && newPage <= Math.ceil(total / pageSize)) {
			setCurrentPage(newPage);
		}
	};

	const handleTableChange: TableProps['onChange'] = (_, __, sorter) => {
		const { columnKey, order } = sorter as SorterResult<string>;

		setTableSort({
			field: Array.isArray(columnKey) ? columnKey[0] : columnKey,
			order: order,
		});
	};

	return (
		<React.Fragment>
			{notificationApiContextHolder}

			<Spin spinning={isFetchingGroupsCharacteristic}>
				<Head
					title={translate('characteristic.manage.title')}
					subtitle={translate('characteristic.manage.subtitle')}
					createUrl='/admin/characteristic/create'
					createIcon='faPlus'
				/>

				<Table
					dataSource={characteristics}
					columns={[
						{
							title: translate('characteristic.manage.table.name'),
							dataIndex: 'name',
							key: 'name',
							sorter: true,
						},
						{
							title: translate('characteristic.manage.table.group'),
							dataIndex: 'groupCharacteristicName',
							key: 'groupCharacteristicName',
							align: 'center',
							sorter: true,
							render: (_, record: any): JSX.Element => (
								<Tag bordered={false} color='processing'>
									{record.groupCharacteristicName}
								</Tag>
							),
						},
						{
							title: translate('characteristic.manage.table.type'),
							dataIndex: 'type',
							key: 'type',
							align: 'center',
							sorter: true,
							render: (_, record: any): JSX.Element => (
								<Tag bordered={false} color='processing'>
									{record.type === 'alphanumeric' && translate('characteristic.manage.table.type.alphanumeric')}
									{record.type === 'specific' && translate('characteristic.manage.table.type.specific')}
								</Tag>
							),
						},
						{
							title: translate('characteristic.manage.table.updated_at'),
							dataIndex: 'updatedAt',
							key: 'updatedAt',
							sorter: true,
							defaultSortOrder: 'descend',
							render: (_, record: any): JSX.Element => <span>{dayjs(record.updatedAt).format('L LTS')}</span>,
						},
						{
							title: translate('characteristic.manage.table.action'),
							key: 'action',
							width: '0px',
							fixed: 'right',
							render: (_, record: any): JSX.Element => (
								<Button type='text' onClick={(): void => navigate(`/admin/characteristic/edit/${record.uuid}`)}>
									<Icon icon='faPen' color='#71717A' />
								</Button>
							),
						},
					]}
					rowKey='uuid'
					size='middle'
					bordered
					scroll={{ x: true }}
					onChange={handleTableChange}
					pagination={{
						showSizeChanger: false,
						current: currentPage,
						pageSize: pageSize,
						total: total,
						showTotal: (total) => (
							<Tag bordered={false} color='processing'>
								{translate('table.total', { total: total })}
							</Tag>
						),
						onChange: handlePageChange,
						size: 'default',
					}}
				/>
			</Spin>
		</React.Fragment>
	);
};

export default CharacteristicManage;
