import type { DraggableSyntheticListeners, UniqueIdentifier } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import type { CSSProperties, PropsWithChildren } from 'react';
import { createContext, useContext, useMemo } from 'react';

interface Props {
	id: UniqueIdentifier;
}

interface Context {
	attributes: Record<string, any>;
	listeners: DraggableSyntheticListeners;
	ref(node: HTMLElement | null): void;
}

const SortableItemContext = createContext<Context>({
	attributes: {},
	listeners: undefined,
	ref() {
		return null;
	},
});

export const SortableItem = ({ children, id }: PropsWithChildren<Props>): JSX.Element => {
	const { attributes, isDragging, listeners, setNodeRef, setActivatorNodeRef, transform, transition } = useSortable({
		id,
	});

	const context = useMemo(
		() => ({
			attributes,
			listeners,
			ref: setActivatorNodeRef,
		}),
		[attributes, listeners, setActivatorNodeRef],
	);

	const style: CSSProperties = {
		opacity: isDragging ? 0.4 : undefined,
		transform: CSS.Translate.toString(transform),
		transition,
	};

	return (
		<SortableItemContext.Provider value={context}>
			{/* className='flex justify-between items-center flex-[1_0_200px] py-[18px] px-3 bg-[#fafafa] border-[1px] border-solid border-[#e4e4e7] text-black box-border rounded-lg' */}

			<div
				className='min-w-12 h-48 flex items-center bg-[#fafafa] border-[1px] border-solid border-[#e4e4e7] text-black box-border rounded-lg'
				ref={setNodeRef}
				style={style}
			>
				{children}
			</div>
		</SortableItemContext.Provider>
	);
};

export const DragHandle = (): JSX.Element => {
	const { attributes, listeners, ref } = useContext(SortableItemContext);

	return (
		<button
			className='flex px-[10px] h-full items-center justify-center flex-[0_0_auto] rounded-lg border-none outline-none appearance-none cursor-grab bg-transparent hover:bg-[rgba(0,_0,_0,_0.05)] transition-all'
			{...attributes}
			{...listeners}
			ref={ref}
		>
			<svg className='flex-[0_0_auto] m-auto h-full overflow-visible fill-[#919eab]' viewBox='0 0 20 20' width='12'>
				<path d='M7 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 2zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 14zm6-8a2 2 0 1 0-.001-4.001A2 2 0 0 0 13 6zm0 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 14z'></path>
			</svg>
		</button>
	);
};
