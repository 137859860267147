import { Spin } from 'antd';
import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import SessionContext from '../Context/SessionContext';

type Props = {
	children: JSX.Element;
};

const AuthenticatedRouting = ({ children }: Props): JSX.Element => {
	const { user } = useContext(SessionContext);

	if (user) {
		return children;
	} else if (user === undefined) {
		return <Spin spinning fullscreen />;
	} else {
		return <Navigate to='/login' />;
	}
};

export default AuthenticatedRouting;
