import { Segmented, notification } from 'antd';
import React, { useState } from 'react';
import { Head } from '../../../Component/Head/Head';
import { Main } from '../../../Component/Main/Main';
import useTranslate from '../../../Hook/useTranslate';
import PropertyCharacteristic from './_Component/PropertyCharacteristic';
import PropertyGeneral from './_Component/PropertyGeneral';
import PropertyImage from './_Component/PropertyImage';

const PropertyEdit = (): JSX.Element => {
	const { translate } = useTranslate();
	const [notificationApi, notificationApiContextHolder] = notification.useNotification();

	const [segmentedValue, setSegmentedValue] = useState('general');

	const options: { label: string; value: string }[] = [
		{
			label: translate('property.edit.segmented.general'),
			value: 'general',
		},
		{
			label: translate('property.edit.segmented.characteristics'),
			value: 'characteristics',
		},
		{
			label: translate('property.edit.segmented.images'),
			value: 'images',
		},
	];

	const renderContent = (value: string): JSX.Element => {
		switch (value) {
			case 'general':
				return <PropertyGeneral />;
			case 'characteristics':
				return <PropertyCharacteristic />;
			case 'images':
				return <PropertyImage />;
			default:
				return null;
		}
	};

	return (
		<React.Fragment>
			{notificationApiContextHolder}

			<Main>
				<Head title={translate('property.edit.title')} subtitle={translate('property.edit.subtitle')} marginBottom='64px' />

				<div className='flex justify-center mb-16'>
					<Segmented size='large' onChange={setSegmentedValue} options={options} />
				</div>

				{renderContent(segmentedValue)}
			</Main>
		</React.Fragment>
	);
};

export default PropertyEdit;
