import { Button, Popconfirm, Spin, UploadProps, notification } from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import { GetProp } from 'antd/lib';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Icon from '../../../../Component/Icon/Icon';
import { GetPropertyImagesByPropertyUuidResponseDto } from '../../../../Dto/Response/GetPropertyImagesByPropertyUuidResponseDto';
import { UploadImageResponseDto } from '../../../../Dto/Response/UploadImageResponseDto';
import useTranslate from '../../../../Hook/useTranslate';
import * as apiService from '../../../../Service/ApiService';
import ImagePreview from '../../_Component/ImagePreview';
import { SortableList } from '../../_Component/SortableList';

const PropertyImage = (): JSX.Element => {
	const { uuid } = useParams();
	const { translate } = useTranslate();
	const [notificationApi, notificationApiContextHolder] = notification.useNotification();

	const [images, setImages] = useState([]);
	const [imagePreviewVisible, setImagePreviewVisible] = useState<boolean>(false);
	const [imagePreviewUrl, setImagePreviewUrl] = useState<string>('');

	const [isFetchingPropertyImages, setIsFetchingPropertyImages] = useState<boolean>(false);

	const getPropertyImagesByPropertyUuid = async (): Promise<void> => {
		setIsFetchingPropertyImages(true);

		try {
			const result = await apiService.makeRequest<GetPropertyImagesByPropertyUuidResponseDto>({
				route: `property/${uuid}/images`,
				method: 'GET',
			});

			setImages(result.images);
		} catch (error) {
			notificationApi.error({
				message: translate('error'),
				description: `${error}`,
			});

			console.error(error);
		} finally {
			setIsFetchingPropertyImages(false);
		}
	};

	useEffect(() => {
		getPropertyImagesByPropertyUuid();
	}, [uuid]);

	const deleteImage = async (propertyImageRelationUuid: string): Promise<void> => {
		setIsFetchingPropertyImages(true);

		try {
			await apiService.makeRequest<{ message: string }>({
				route: `property/${uuid}/delete-image-relation/${propertyImageRelationUuid}`,
				method: 'DELETE',
			});

			getPropertyImagesByPropertyUuid();
		} catch (error) {
			notificationApi.error({
				message: translate('error'),
				description: `${error}`,
			});

			console.error(error);
		} finally {
			setIsFetchingPropertyImages(false);
		}
	};

	const updatePropertyImageRelationsPositions = async (items: any): Promise<void> => {
		setImages(items);
		setIsFetchingPropertyImages(true);

		try {
			await apiService.makeRequest<{ message: string }>({
				route: `property/${uuid}/update-images-positions`,
				method: 'POST',
				data: {
					propertyImageRelations: items,
				},
			});

			getPropertyImagesByPropertyUuid();
		} catch (error) {
			notificationApi.error({
				message: translate('error'),
				description: `${error}`,
			});

			console.error(error);
		} finally {
			setIsFetchingPropertyImages(false);
		}
	};

	type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

	const handleFileChange = (info: any): void => {
		const file = info.file.originFileObj as FileType;

		const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

		if (!isJpgOrPng) {
			notificationApi.error({
				message: translate('error'),
				description: translate('property.edit.image_restriction'),
			});
			return;
		}

		const reader = new FileReader();

		reader.addEventListener('load', async () => {
			setIsFetchingPropertyImages(true);
			const result = await uploadImage(reader.result as string);
			setIsFetchingPropertyImages(false);

			await createPropertyImageRelation(result.image.uuid, result.thumbnail.uuid, result.optimized.uuid);
			await getPropertyImagesByPropertyUuid();
		});

		reader.readAsDataURL(file);
	};

	const uploadImage = async (base64: string): Promise<UploadImageResponseDto> => {
		try {
			return await apiService.makeRequest<UploadImageResponseDto>({
				route: 'image/upload',
				method: 'POST',
				data: {
					base64,
				},
			});
		} catch (error) {
			notificationApi.error({
				message: translate('error'),
				description: `${error}`,
			});

			console.error(error);
		}
	};

	const createPropertyImageRelation = async (imageUuid: string, thumbnailUuid: string, optimizedUuid: string): Promise<void> => {
		setIsFetchingPropertyImages(true);

		try {
			await apiService.makeRequest<{ message: string }>({
				route: `property/${uuid}/create-image-relation`,
				method: 'POST',
				data: {
					imageUuid: imageUuid,
					thumbnailUuid: thumbnailUuid,
					optimizedUuid: optimizedUuid,
				},
			});
		} catch (error) {
			notificationApi.error({
				message: translate('error'),
				description: `${error}`,
			});

			console.error(error);
		} finally {
			setIsFetchingPropertyImages(false);
		}
	};

	const showImage = (imageUrl: string): void => {
		setImagePreviewUrl(imageUrl);
		setImagePreviewVisible(true);
	};

	const imagePreviewClose = (): void => {
		setImagePreviewVisible(false);
		setImagePreviewUrl('');
	};

	return (
		<React.Fragment>
			{notificationApiContextHolder}

			<Spin spinning={isFetchingPropertyImages}>
				<div className='flex flex-col gap-[10px]'>
					<Dragger showUploadList={false} customRequest={(): void => undefined} onChange={handleFileChange} className='mb-5'>
						<Icon icon='faImages' color='#5078FD' size={24} />
						<div className='font-semibold'>{translate('property.edit.upload_image')}</div>
						<div>{translate('property.edit.upload_image_description')}</div>
					</Dragger>

					<SortableList
						items={images}
						onChange={(items): Promise<void> => updatePropertyImageRelationsPositions(items)}
						renderItem={(image): JSX.Element => (
							<SortableList.Item id={image.id}>
								<div className='relative flex justify-between items-center w-full h-full gap-3 p-3 box-border'>
									<div className='h-8'>
										<SortableList.DragHandle />
									</div>

									<div className='relative w-full h-full rounded-lg overflow-hidden'>
										<div
											className='w-full h-full group cursor-pointer'
											style={{
												background: `url(${image.thumbnailUrl})`,
												backgroundRepeat: 'no-repeat',
												backgroundSize: 'cover',
												backgroundPosition: 'center',
											}}
											onClick={(): void => showImage(image.imageUrl)}
										>
											<div className='bg-black bg-opacity-70 flex justify-center items-center text-white w-full h-full opacity-0 group-hover:opacity-100 transition-all'>
												Voir l'image
											</div>
										</div>

										<div
											className={`${
												images[0].id === image.id ? 'bg-[#FAAD14] text-white' : 'bg-white'
											} absolute top-2 right-2 flex justify-center items-center px-[10px] py-1 text-sm rounded-lg`}
										>
											{images.indexOf(image) + 1}
										</div>

										<Popconfirm
											placement='topRight'
											icon={
												<div className='mr-2'>
													<Icon icon='faWarning' color='#F31260' size={15} />
												</div>
											}
											title={translate('property.edit.delete_image')}
											okText={translate('property.edit.confirm_delete_image')}
											okButtonProps={{ danger: true }}
											cancelText={translate('property.edit.cancel_delete_image')}
											onConfirm={(): Promise<void> => deleteImage(image.id)}
										>
											<Button className='bg-white hover:!bg-[#FEE7EF] absolute bottom-2 right-2 flex justify-center items-center border-none rounded-lg h-7 w-6'>
												<Icon icon='faTrash' color='#F31260' />
											</Button>
										</Popconfirm>
									</div>
								</div>
							</SortableList.Item>
						)}
					/>
				</div>
			</Spin>

			{imagePreviewVisible && <ImagePreview isVisible={imagePreviewVisible} imageUrl={imagePreviewUrl} onClose={imagePreviewClose} />}
		</React.Fragment>
	);
};

export default PropertyImage;
