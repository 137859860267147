import { Button, Dropdown, Select, Spin } from 'antd';
import { MenuProps } from 'antd/lib';
import React, { useContext, useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import SessionContext from '../../Context/SessionContext';
import { GetUserMenuResponseDto } from '../../Dto/Response/GetUserMenuResponseDto';
import useTranslate from '../../Hook/useTranslate';
import * as apiService from '../../Service/ApiService';
import Icon from '../Icon/Icon';
import Menu from '../Menu/Menu';
import { ItemType } from '../Menu/_Type/ItemType';

const { Option } = Select;

const Navbar = (): JSX.Element => {
	// const { language, setLanguageCode, languages } = useTranslate();
	const navigate: NavigateFunction = useNavigate();
	const { translate } = useTranslate();
	const { user, logoutUser, mode, setMode } = useContext(SessionContext);

	const [isFetchingMenu, setIsFetchingMenu] = useState<boolean>(false);
	const [menuItems, setMenuItems] = useState<ItemType[]>([]);

	const currentTeam: string = localStorage.getItem('currentTeam');

	const handleTeamChange = (teamUuid: string): void => {
		localStorage.setItem('currentTeam', teamUuid);
		navigate('/');
	};

	const handleModeChange = (): void => {
		const newMode = mode === 'team' ? 'admin' : 'team';

		setMode(newMode);

		if (newMode === 'team') {
			navigate('/property/manage');
		} else {
			navigate('/admin/characteristic/manage');
		}
	};

	useEffect(() => {
		const getUserMenu = async (): Promise<void> => {
			setIsFetchingMenu(true);

			try {
				const result = await apiService.makeRequest<GetUserMenuResponseDto[]>({
					route: 'user/menu',
					method: 'POST',
					data: {
						mode: mode,
					},
				});

				setMenuItems(result);
			} catch (error) {
				console.error(error);
			} finally {
				setIsFetchingMenu(false);
			}
		};

		getUserMenu();
	}, [mode]);

	const items: MenuProps['items'] = [
		{
			label: translate('menu.logout'),
			key: '1',
			danger: true,
			onClick: (): void => {
				logoutUser();
			},
		},
	];

	return (
		<React.Fragment>
			<Spin spinning={isFetchingMenu} fullscreen />

			<div className='sticky flex flex-col top-[40px] w-full justify-between overflow-y-auto'>
				<div className='flex flex-col gap-[40px] '>
					<div className='flex justify-center items-center text-2xl font-bold'>ImmERP</div>

					<Menu items={menuItems} />
				</div>

				<div className='flex flex-col gap-2'>
					<div className='flex flex-col gap-[10px] p-4 box-border rounded-xl bg-white shadow-immerp'>
						<div className='flex justify-between gap-2 items-center'>
							<span className='text-[16px] font-semibold truncate'>
								{user.firstname} {user.lastname}
							</span>

							<Dropdown menu={{ items }} trigger={['click']} placement='top'>
								<div className='flex justify-center shrink-0 items-center bg-[#F4F4F5] h-[26px] w-[26px] rounded-lg cursor-pointer'>
									<Icon icon='faGear' size={13} />
								</div>
							</Dropdown>
						</div>

						{mode === 'team' && (
							<Select
								onChange={handleTeamChange}
								defaultValue={currentTeam}
								size='middle'
								placement='topLeft'
								className='w-full'
							>
								{user.teams.map((team) => (
									<Option key={team.uuid} value={team.uuid}>
										{team.name}
									</Option>
								))}
							</Select>
						)}
					</div>

					{user.globalRole === 'admin' && (
						<Button onClick={handleModeChange} type='primary'>
							<div className='flex justify-center items-center gap-2'>
								<Icon icon='faRepeat' color='#FFFFFF' />
								<span>{mode === 'team' ? translate('menu.mode_admin') : translate('menu.mode_team')}</span>
							</div>
						</Button>
					)}
				</div>

				{/* </ConfigProvider> */}
				{/* <Select onChange={(e) => setLanguageCode(e)} value={language.code} size="large">
                {languages.map((language) => {
                    return language.available
                        ? <Option key={language.code} value={language.code}>
                            {language.label}
                        </Option>
                        : null

                })}
            </Select> */}
			</div>
		</React.Fragment>
	);
};

export default Navbar;
