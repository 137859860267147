import { Button, Collapse, Empty, Form, Input, Select, Spin, message, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
	GetGroupsCharacteristicAndCharacteristicsResponseDto,
	GroupResponse,
} from '../../../../Dto/Response/GetGroupsCharacteristicAndCharacteristicsResponseDto';
import {
	AlphanumericCharacteristics,
	GetPropertyCharacteristicsByPropertyUuidResponseDto,
	PropertyAlphanumericCharacteristics,
	PropertySpecificCharacteristics,
} from '../../../../Dto/Response/GetPropertyCharacteristicsByPropertyUuidResponseDto';
import useDebounce from '../../../../Hook/useDebounce';
import useTranslate from '../../../../Hook/useTranslate';
import * as apiService from '../../../../Service/ApiService';

const { Option } = Select;

const PropertyCharacteristic = (): JSX.Element => {
	const { uuid } = useParams();
	const { translate } = useTranslate();
	const [notificationApi, notificationApiContextHolder] = notification.useNotification();

	const [propertyCharacteristicsForm] = Form.useForm();

	const [alphanumericCharacteristics, setAlphanumericCharacteristics] = useState<AlphanumericCharacteristics[]>([]);
	const [propertyAlphanumericCharacteristics, setPropertyAlphanumericCharacteristics] = useState<PropertyAlphanumericCharacteristics[]>(
		[],
	);
	const [propertySpecificCharacteristics, setPropertySpecificCharacteristics] = useState<PropertySpecificCharacteristics[]>([]);
	const [characteristicsQuery, setCharacteristicsQuery] = useState<string>('');
	const debounceCharacteristicsQuery = useDebounce<string>(characteristicsQuery, 500);
	const [groupsCharacteristic, setGroupsCharacteristic] = useState<GroupResponse[]>([]);

	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [isFetchingPropertyCharacteristics, setIsFetchingPropertyCharacteristics] = useState<boolean>(false);
	const [isFetchingGroupsCharacteristicAndCharacteristics, setIsFetchingGroupsCharacteristicAndCharacteristics] =
		useState<boolean>(false);

	const [activeKeys, setActiveKeys] = useState<string[]>([]);

	const fetchPropertyCharacteristics = async (): Promise<void> => {
		setIsFetchingPropertyCharacteristics(true);

		try {
			const result = await apiService.makeRequest<GetPropertyCharacteristicsByPropertyUuidResponseDto>({
				route: `property/${uuid}/characteristics`,
				method: 'GET',
			});

			setAlphanumericCharacteristics(result.alphanumericCharacteristics);
			setPropertyAlphanumericCharacteristics(result.propertyCharacteristics.propertyAlphanumericCharacteristics);
			setPropertySpecificCharacteristics(result.propertyCharacteristics.propertySpecificCharacteristics);
		} catch (error) {
			notificationApi.error({
				message: translate('error'),
				description: `${error}`,
			});

			console.error(error);
		} finally {
			setIsFetchingPropertyCharacteristics(false);
		}
	};

	useEffect(() => {
		fetchPropertyCharacteristics();
	}, [uuid]);

	useEffect(() => {
		const fetchGroupsCharacteristicAndCharacteristics = async (): Promise<void> => {
			setIsFetchingGroupsCharacteristicAndCharacteristics(true);

			try {
				const result = await apiService.makeRequest<GetGroupsCharacteristicAndCharacteristicsResponseDto>({
					route: 'group-characteristic/characteristics',
					method: 'POST',
					data: { query: debounceCharacteristicsQuery },
				});

				setGroupsCharacteristic(result.groups);

				const keys = result.groups.filter((group) => group.characteristics.length > 0).map((group) => group.uuid);

				setActiveKeys(keys);
			} catch (error) {
				notificationApi.error({
					message: translate('error'),
					description: `${error}`,
				});

				console.error(error);
			} finally {
				setIsFetchingGroupsCharacteristicAndCharacteristics(false);
			}
		};

		fetchGroupsCharacteristicAndCharacteristics();
	}, [debounceCharacteristicsQuery]);

	const getSpecificCharacteristicValue = (code: string): string | undefined => {
		const characteristic = propertySpecificCharacteristics.find((c) => c.code === code);
		return characteristic ? characteristic.valueCode : undefined;
	};

	const getAlphanumericCharacteristicValue = (code: string): string | undefined => {
		const characteristic = propertyAlphanumericCharacteristics.find((c) => c.code === code);
		return characteristic ? characteristic.value : undefined;
	};

	const submit = async (): Promise<void> => {
		Promise.resolve(propertyCharacteristicsForm.getFieldsValue(true))
			.then(async (values: any) => {
				const characteristics = Object.keys(values).map((valueKey: any) => {
					const value = values[valueKey];

					const alphanumericCharacteristic = alphanumericCharacteristics.find((c) => c.code === valueKey);

					return {
						code: valueKey,
						type: alphanumericCharacteristic ? 'alphanumeric' : 'specific',
						value: value,
						valueType: alphanumericCharacteristic ? alphanumericCharacteristic.valueType : null,
					};
				});

				setIsSubmitting(true);

				try {
					await apiService.makeRequest({
						route: `property/${uuid}/update-characteristics`,
						method: 'POST',
						data: { characteristics: characteristics },
					});

					message.success(translate('property.edit.success'));

					fetchPropertyCharacteristics();
				} catch (error) {
					notificationApi.error({
						message: translate('error'),
						description: `${error}`,
					});

					console.error(error);
				} finally {
					setIsSubmitting(false);
				}
			})
			.catch((error) => {
				notificationApi.error({
					message: translate('error'),
					description: `${error}`,
				});

				console.error(error);
			});
	};

	return (
		<React.Fragment>
			{notificationApiContextHolder}

			<Spin spinning={isSubmitting || isFetchingPropertyCharacteristics} fullscreen />

			<Form form={propertyCharacteristicsForm} onFinish={submit} layout='vertical' className='flex flex-col justify-between h-full'>
				<Spin spinning={isFetchingGroupsCharacteristicAndCharacteristics || isFetchingPropertyCharacteristics}>
					<div className='flex flex-col gap-4'>
						<Input
							onChange={(e): void => setCharacteristicsQuery(e.target.value)}
							placeholder={translate('property.edit.characteristics.search')}
							size='large'
							type='text'
							className='w-full 650:w-1/2'
						/>

						<div className='flex flex-col gap-4'>
							{groupsCharacteristic.map((groupCharacteristic) => (
								<React.Fragment key={groupCharacteristic.uuid}>
									{/* BUG Collapse is not rendered until open so data are not accessible on submit */}
									<div style={{ display: 'none' }}>
										{groupCharacteristic.characteristics.map((characteristic: any) => (
											<Form.Item
												key={characteristic.uuid}
												name={characteristic.code}
												initialValue={
													characteristic.type === 'specific'
														? getSpecificCharacteristicValue(characteristic.code)
														: getAlphanumericCharacteristicValue(characteristic.code)
												}
											>
												{characteristic.type === 'specific' && (
													<Select size='large' className='w-full' allowClear>
														{characteristic.values.map((value: any) => (
															<Option key={value.uuid} value={value.code}>
																{value.value}
															</Option>
														))}
													</Select>
												)}

												{characteristic.type === 'alphanumeric' && (
													<Input size='large' className='w-full' addonAfter={characteristic.unit} />
												)}
											</Form.Item>
										))}
									</div>

									<Collapse
										activeKey={activeKeys.includes(groupCharacteristic.uuid) ? ['1'] : []}
										onChange={(): void => {
											if (activeKeys.includes(groupCharacteristic.uuid)) {
												setActiveKeys(activeKeys.filter((key) => key !== groupCharacteristic.uuid));
											} else {
												setActiveKeys([...activeKeys, groupCharacteristic.uuid]);
											}
										}}
										items={[
											{
												key: '1',
												label: (
													<div className='flex justify-between'>
														{groupCharacteristic.name}{' '}
														<span className='text-gray-400'>{groupCharacteristic.characteristics.length}</span>
													</div>
												),
												children: (
													<div className='flex flex-wrap p-4 gap-2'>
														{groupCharacteristic.characteristics.length === 0 && (
															<Empty
																image={Empty.PRESENTED_IMAGE_SIMPLE}
																className='flex justify-center items-center w-full'
															/>
														)}

														{groupCharacteristic.characteristics.map((characteristic: any) => (
															<div
																key={characteristic.uuid}
																className='bg-[#F4F4F5] flex w-full 1k450:w-[calc(50%-4px)] box-border rounded-lg p-4 gap-2'
															>
																<div className='flex flex-col justify-center gap-1 w-1/2'>
																	<div className='font-bold'>{characteristic.name}</div>

																	<div className='text-[#707477] text-xs'>
																		Code : {characteristic.code}
																	</div>
																</div>

																<div className='flex w-1/2'>
																	<Form.Item name={characteristic.code} className='w-full'>
																		{characteristic.type === 'specific' && (
																			<Select size='large' allowClear>
																				{characteristic.values.map((value: any) => (
																					<Option key={value.uuid} value={value.code}>
																						{value.value}
																					</Option>
																				))}
																			</Select>
																		)}

																		{characteristic.type === 'alphanumeric' && (
																			<Input size='large' addonAfter={characteristic.unit} />
																		)}
																	</Form.Item>
																</div>
															</div>
														))}
													</div>
												),
											},
										]}
									/>
								</React.Fragment>
							))}
						</div>
					</div>
				</Spin>

				<Form.Item className='flex justify-end mt-10'>
					<Button
						disabled={isSubmitting || isFetchingGroupsCharacteristicAndCharacteristics || isFetchingPropertyCharacteristics}
						size='large'
						block
						type='primary'
						htmlType='submit'
					>
						{translate('property.edit.save')}
					</Button>
				</Form.Item>
			</Form>
		</React.Fragment>
	);
};

export default PropertyCharacteristic;
