import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

export interface ApiServiceOptions {
	method: 'GET' | 'POST' | 'PUT' | 'DELETE';
	route: string;
	data?: any;
}

type CustomAxiosErrorResponse = {
	path: string;
	method: string;
	status: number;
	error: string;
	message: string;
};

const baseUrl: string = process.env.REACT_APP_API_BASE_URL || '';

const setAuthorizationHeader = (): void => {
	const token = localStorage.getItem('token');

	if (token) {
		axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	} else {
		delete axios.defaults.headers.common['Authorization'];
	}
};

const setTeamHeader = (): void => {
	const currentTeam = localStorage.getItem('currentTeam');

	if (currentTeam) {
		axios.defaults.headers.common['x-team-uuid'] = currentTeam;
	}
};

const setFrontUrlHeader = (): void => {
	axios.defaults.headers.common['x-front-url'] = window.location.href;
};

const buildUrl = (route: string): string => {
	return `${baseUrl}/api/${route}`;
};

const handleRequest = async <T>(options: ApiServiceOptions, logoutCallback?: () => void): Promise<T> => {
	setAuthorizationHeader();
	setTeamHeader();
	setFrontUrlHeader();

	const config: AxiosRequestConfig = {
		method: options.method,
		url: buildUrl(options.route),
		data: options.data,
	};

	try {
		const response: AxiosResponse<T> = await axios<T>(config);

		return response.data;
	} catch (error) {
		if (axios.isAxiosError(error)) {
			const axiosError: AxiosError = error;

			if (axiosError.response) {
				const { path, method, status, error, message }: CustomAxiosErrorResponse = axiosError.response.data as any;

				let errorMessage = `Path: ${path}\n`;
				errorMessage += `Method: ${method}\n`;
				errorMessage += `Status: ${status}\n`;
				errorMessage += `Error: ${error}\n`;
				errorMessage += `Message: ${message}\n`;

				// TODO
				if (status === 401 && logoutCallback) {
					//logoutCallback();
				}

				throw new Error(errorMessage);
			} else {
				throw new Error('Network error occurred');
			}
		} else {
			throw new Error('Unknown error occurred');
		}
	}
};

export const makeRequest = async <T>(options: ApiServiceOptions, logoutCallback?: () => void): Promise<T> => {
	return handleRequest<T>(options, logoutCallback);
};
