import { Route, Routes } from 'react-router-dom';
import AuthenticatedRouting from './AuthenticatedRouting';

import AuthenticationLayout from '../Layout/AuthenticationLayout';
import RootLayout from '../Layout/RootLayout';

const Routing = () => {
	return (
		<Routes>
			<Route path='login' element={<AuthenticationLayout />} />

			<Route
				path='*'
				element={
					<AuthenticatedRouting>
						<RootLayout />
					</AuthenticatedRouting>
				}
			/>
		</Routes>
	);
};

export default Routing;
