import { Divider, Form, Select, Spin, Switch, Table, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { Head } from '../../../Component/Head/Head';
import { Main } from '../../../Component/Main/Main';
import { GetTeamsResponseDto } from '../../../Dto/Response/GetTeamsResponseDto';
import useTranslate from '../../../Hook/useTranslate';
import * as apiService from '../../../Service/ApiService';

const { Option } = Select;

const PermissionTeam = () => {
	const { translate } = useTranslate();
	const [permissionTeamForm] = Form.useForm();
	const [notificationApi, notificationApiContextHolder] = notification.useNotification();

	const [teams, setTeams] = useState<{ uuid: string; name: string }[]>([]);
	const [teamRoles, setTeamRoles] = useState<{ uuid: string; name: string }[]>([]);
	const [teamRolePermissions, setTeamRolePermissions] = useState<any>([]);

	const [isFetchingTeams, setIsFetchingTeams] = useState<boolean>(false);
	const [isFetchingTeamRoles, setIsFetchingTeamRoles] = useState<boolean>(false);
	const [isFetchingTeamRolePermissions, setIsFetchingTeamRolePermissions] = useState<boolean>(false);
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

	useEffect(() => {
		const getTeams = async (): Promise<void> => {
			setIsFetchingTeams(true);

			try {
				const result = await apiService.makeRequest<GetTeamsResponseDto[]>({
					route: 'team',
					method: 'GET',
				});

				setTeams(result);
			} catch (error) {
				notificationApi.error({
					message: translate('error'),
					description: `${error}`,
				});

				console.error(error);
			} finally {
				setIsFetchingTeams(false);
			}
		};

		getTeams();
	}, []);

	const getTeamRoles = async (teamUuid: string): Promise<void> => {
		setIsFetchingTeamRoles(true);

		try {
			const result = await apiService.makeRequest<any>({
				route: `team-role/team/${teamUuid}`,
				method: 'GET',
			});

			setTeamRoles(result);
		} catch (error) {
			notificationApi.error({
				message: translate('error'),
				description: `${error}`,
			});

			console.error(error);
		} finally {
			setIsFetchingTeamRoles(false);
		}
	};

	const getTeamRolePermissions = async (teamRoleUuid: string): Promise<void> => {
		setIsFetchingTeamRolePermissions(true);

		try {
			const result = await apiService.makeRequest<any>({
				route: `team-role/${teamRoleUuid}/permissions`,
				method: 'GET',
			});

			setTeamRolePermissions(result);
		} catch (error) {
			notificationApi.error({
				message: translate('error'),
				description: `${error}`,
			});

			console.error(error);
		} finally {
			setIsFetchingTeamRolePermissions(false);
		}
	};

	const submit = async (key: string, value: boolean): Promise<void> => {
		setIsSubmitting(true);

		try {
			const teamRoleUuid: string = permissionTeamForm.getFieldValue('role');

			await apiService.makeRequest<any>({
				route: `team-role/update/permission/${teamRoleUuid}`,
				method: 'POST',
				data: {
					key: key,
					value: value,
				},
			});

			getTeamRolePermissions(teamRoleUuid);
		} catch (error) {
			notificationApi.error({
				message: translate('error'),
				description: `${error}`,
			});

			console.error(error);
		} finally {
			setIsSubmitting(false);
		}
	};

	const handleTeamChange = (teamUuid: string): void => {
		permissionTeamForm.setFieldValue('role', null);
		setTeamRolePermissions([]);
		getTeamRoles(teamUuid);
	};

	const handleTeamRoleChange = (teamRoleUuid: string): void => {
		setTeamRolePermissions([]);
		getTeamRolePermissions(teamRoleUuid);
	};

	const handlePermissionChange = (key: string, value: boolean): void => {
		submit(key, value);
	};

	return (
		<React.Fragment>
			{notificationApiContextHolder}

			<Main>
				<Spin spinning={isFetchingTeams || isFetchingTeamRoles || isFetchingTeamRolePermissions || isSubmitting}>
					<Head
						title={translate('permission.teams.title')}
						subtitle={translate('permission.teams.subtitle')}
						marginBottom='64px'
					/>

					<Form form={permissionTeamForm} layout='vertical' className='flex flex-col justify-between h-full'>
						<div className='flex flex-col gap-[20px]'>
							<div className='flex flex-col 650:flex-row gap-[20px]'>
								<Form.Item name='team' label={translate('permission.teams.team')} required className='w-full 650:w-1/2'>
									<Select onChange={handleTeamChange} size='large' showSearch allowClear optionFilterProp='children'>
										{teams.map((team) => (
											<Option key={team.uuid} value={team.uuid}>
												{team.name}
											</Option>
										))}
									</Select>
								</Form.Item>

								<Form.Item name='role' label={translate('permission.teams.role')} required className='w-full 650:w-1/2'>
									<Select onChange={handleTeamRoleChange} size='large' showSearch allowClear optionFilterProp='children'>
										{teamRoles.map((teamRole) => (
											<Option key={teamRole.uuid} value={teamRole.uuid}>
												{teamRole.name}
											</Option>
										))}
									</Select>
								</Form.Item>
							</div>

							<Divider orientation='left'>
								<span className='text-lg font-medium'>{translate('permission.teams.list')}</span>
							</Divider>

							<Table
								dataSource={teamRolePermissions}
								columns={[
									{
										title: translate('permission.teams.manage.category'),
										dataIndex: 'group',
										key: 'group',
										render: (_, record: any) => {
											return record.group
												.map((group: { translate: string }) => translate(`permission.key.group.${group.translate}`))
												.join(', ');
										},
									},
									{
										title: translate('permission.teams.manage.authorization'),
										dataIndex: 'translate',
										key: 'translate',
										render: (_, record: any) => {
											return translate(`permission.key.${record.translate}`);
										},
									},
									{
										title: translate('permission.teams.manage.action'),
										dataIndex: 'value',
										key: 'value',
										align: 'center',
										render: (_, record: any) => (
											<Switch
												defaultValue={record.value}
												onChange={(value) => handlePermissionChange(record.key, value)}
											/>
										),
									},
								]}
								rowKey='key'
								size='middle'
								bordered
								scroll={{ x: true }}
								pagination={false}
							/>
						</div>
					</Form>
				</Spin>
			</Main>
		</React.Fragment>
	);
};

export default PermissionTeam;
