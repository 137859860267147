import { Button, Form, Input, Spin, Upload, UploadProps, message } from 'antd';
import { GetProp } from 'antd/lib';
import JoditEditor from 'jodit-react';
import { useRef, useState } from 'react';
import { UploadImageResponseDto } from '../../../Dto/Response/UploadImageResponseDto';
import useTranslate from '../../../Hook/useTranslate';
import * as apiService from '../../../Service/ApiService';

type itemType = {
	id: string;
	type: 'paragraph' | 'image';
	content: itemContent;
};

type itemContent = {
	text?: string;
	imageUuid?: string;
	imageUrl?: string;
	imageCaption?: string;
};

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

export const EditItem = ({ item, updateItem }: { item: itemType; updateItem: (item: itemType) => void }): JSX.Element => {
	const { translate } = useTranslate();
	const editor = useRef(null);

	const [isUploading, setIsUploading] = useState<boolean>(false);

	const [imageUrl, setImageUrl] = useState<string>(item.content.imageUrl);
	const [imageUuid, setImageUuid] = useState<string>(item.content.imageUuid);
	const [imageCaption, setImageCaption] = useState<string>(item.content.imageCaption);

	const config = {
		language: localStorage.getItem('language').slice(0, -3),
		readonly: false,
		spellcheck: true,
		addNewLine: false,
		showCharsCounter: false,
		showWordsCounter: false,
		showXPathInStatusbar: false,
		askBeforePasteHTML: false,
		askBeforePasteFromWord: false,
		defaultActionOnPaste: 'insert_clear_html' as any,
		buttons: ['undo', 'redo', '|', 'bold', 'italic', 'underline', 'strikethrough', '|', 'superscript', 'subscript', '|', 'ul', 'ol'],
		controls: {
			ul: {
				list: undefined as [],
			},
			ol: {
				list: undefined as [],
			},
		},
	};

	const beforeUpload = (file: FileType): boolean => {
		const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

		if (!isJpgOrPng) {
			message.error(translate('article.edit.image_restriction'));
			return;
		}

		return isJpgOrPng;
	};

	const handleFileChange = (info: any): void => {
		const file = info.file.originFileObj as FileType;

		const reader = new FileReader();

		reader.addEventListener('load', async () => {
			await uploadImage(reader.result as string);
		});

		reader.readAsDataURL(file);
	};

	const uploadImage = async (base64: string): Promise<void> => {
		try {
			setIsUploading(true);

			const result = await apiService.makeRequest<UploadImageResponseDto>({
				route: 'image/upload',
				method: 'POST',
				data: {
					base64,
				},
			});

			setImageUuid(result.image.uuid);
			setImageUrl(result.image.url);

			setIsUploading(false);
		} catch (error) {
			setIsUploading(false);

			console.error(error);
		}
	};

	const handleSave = (): void => {
		if (item.type === 'paragraph') {
			const updatedItem = {
				...item,
				content: { text: editor.current.value },
			};
			updateItem(updatedItem);
		} else if (item.type === 'image') {
			const updatedItem = {
				...item,
				content: {
					imageUuid: imageUuid,
					imageCaption: imageCaption,
					imageUrl: imageUrl,
				},
			};
			updateItem(updatedItem);
		}
	};

	return (
		<Spin spinning={isUploading}>
			<div className='flex flex-col gap-6 px-5 py-4'>
				<div className='text-[22px] font-bold'>{translate('article.edit.edit_item')}</div>

				<div className='flex justify-center items-center flex-col gap-6'>
					{item.type === 'paragraph' && (
						<div className='w-full'>
							<JoditEditor ref={editor} config={config} value={item.content.text} />
						</div>
					)}

					{item.type === 'image' && (
						<Form layout='vertical' className='flex justify-between items-center gap-5 h-full w-full'>
							<Form.Item label className='flex items-center'>
								<Upload
									listType='picture-card'
									showUploadList={false}
									customRequest={(): void => undefined}
									beforeUpload={beforeUpload}
									onChange={handleFileChange}
								>
									{imageUrl ? (
										<img src={imageUrl} alt='avatar' className='w-full' />
									) : (
										<button type='button' className='bg-transparent border-none'>
											<div>{translate('article.edit.add_item.image')}</div>
										</button>
									)}
								</Upload>
							</Form.Item>

							<Form.Item label={translate('article.edit.add_item.description')} className='w-full'>
								<Input.TextArea
									value={imageCaption}
									onChange={(event: React.ChangeEvent<HTMLTextAreaElement>): void => setImageCaption(event.target.value)}
									rows={4}
									showCount
									maxLength={255}
									style={{ resize: 'none' }}
								/>
							</Form.Item>
						</Form>
					)}
				</div>

				<div className='flex justify-end mt-4'>
					<Button disabled={isUploading} onClick={handleSave} size='large' type='primary'>
						{translate('article.edit.save_edit_item')}
					</Button>
				</div>
			</div>
		</Spin>
	);
};
