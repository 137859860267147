import MenuItem from './_Component/MenuItem';
import { ItemType } from './_Type/ItemType';

const Menu = ({ items }: { items: ItemType[] }): JSX.Element => {
	return (
		<div className='flex flex-col w-full'>
			<div className='flex flex-col gap-1'>
				{items.map((item) => (
					<MenuItem key={item.id} {...item} />
				))}
			</div>
		</div>
	);
};

export default Menu;
