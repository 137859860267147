import { useContext, useEffect } from 'react';
import LanguageContext from '../Context/LanguageContext';

const useTranslate = () => {
	const context = useContext(LanguageContext);

	if (!context) {
		throw new Error('useTranslate must be used within a LanguageContextProvider');
	}

	const { language, setLanguageCode, languages } = context;

	useEffect(() => {
		localStorage.setItem('language', language.code);
	}, [language]);

	const replaceVariables = (text: string, variables: Record<string, string | number>): string => {
		let translatedText = text;

		for (const [key, value] of Object.entries(variables)) {
			translatedText = translatedText.replace(new RegExp(`{{${key}}}`, 'g'), value.toString());
		}

		return translatedText;
	};

	const translate = (key: string, variables?: Record<string, string | number>): string => {
		if (language.translations === undefined) {
			return `Empty translation file for code : ${language.code}`;
		}

		let translatedText = language.translations[key] || `Missing : [code: ${language.code}, key: ${key}]`;

		if (variables) {
			translatedText = replaceVariables(translatedText, variables);
		}

		return translatedText;
	};

	return { language, setLanguageCode, languages, translate };
};

export default useTranslate;
