import React from "react";
import { Main } from "../../../Component/Main/Main";

const PermissionUser = () => {

    return (
        <React.Fragment>
            <Main>
                PermissionUser
            </Main>
        </React.Fragment>

    );
};

export default PermissionUser;
