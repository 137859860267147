import { Button } from 'antd';
import { Link } from 'react-router-dom';
import Icon from '../Icon/Icon';
import { HeadProps } from './_Prop/HeadProps';

export const Head = ({
	title,
	titleSize = '22px',
	titleWeight = '700',
	subtitle,
	subtitleSize = '14px',
	subtitleWeight = '400',
	createUrl,
	createIcon,
	createIconColor = '#FFFFFF',
	marginBottom = '60px',
}: HeadProps): JSX.Element => {
	return (
		<div className='flex justify-between items-center' style={{ marginBottom }}>
			<div className='flex flex-col gap-[5px]'>
				<span style={{ fontSize: titleSize, fontWeight: titleWeight }}>{title}</span>
				<span style={{ fontSize: subtitleSize, fontWeight: subtitleWeight }} className='text-gray-400'>
					{subtitle}
				</span>
			</div>

			{createUrl && (
				<Link to={createUrl}>
					<Button type='primary' icon={<Icon icon={createIcon} color={createIconColor} />} />
				</Link>
			)}
		</div>
	);
};
