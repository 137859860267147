import { Button, Form, Input, Spin, message, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Head } from '../../../Component/Head/Head';
import { Main } from '../../../Component/Main/Main';
import useTranslate from '../../../Hook/useTranslate';
import * as apiService from '../../../Service/ApiService';

const GroupCharacteristicEdit = (): JSX.Element => {
	const { uuid } = useParams();
	const { translate } = useTranslate();
	const navigate = useNavigate();
	const [groupCharacteristicEditForm] = Form.useForm();
	const [notificationApi, notificationApiContextHolder] = notification.useNotification();

	const [isFetchingGroupCharacteristic, setIsFetchingGroupCharacteristic] = useState<boolean>(false);
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

	useEffect(() => {
		const getGroupCharacteristicByUuid = async (): Promise<void> => {
			setIsFetchingGroupCharacteristic(true);

			try {
				const result = await apiService.makeRequest<any>({
					route: `group-characteristic/${uuid}`,
					method: 'GET',
				});

				groupCharacteristicEditForm.setFieldsValue({
					name: result.name,
					description: result.description,
				});
			} catch (error) {
				notificationApi.error({
					message: translate('error'),
					description: `${error}`,
				});

				console.error(error);
			} finally {
				setIsFetchingGroupCharacteristic(false);
			}
		};

		getGroupCharacteristicByUuid();
	}, [uuid, groupCharacteristicEditForm]);

	const submit = async (values: any): Promise<void> => {
		setIsSubmitting(true);

		try {
			await apiService.makeRequest({
				route: `group-characteristic/update/${uuid}`,
				method: 'POST',
				data: {
					...values,
				},
			});

			message.success(translate('group.characteristic.edit.success'));

			navigate('/admin/characteristic/manage');
		} catch (error) {
			notificationApi.error({
				message: translate('error'),
				description: `${error}`,
			});

			console.error(error);
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<React.Fragment>
			{notificationApiContextHolder}

			<Spin spinning={isSubmitting} fullscreen />

			<Main>
				<Head
					title={translate('group.characteristic.edit.title')}
					subtitle={translate('group.characteristic.edit.subtitle')}
					marginBottom='64px'
				/>

				<Form
					form={groupCharacteristicEditForm}
					onFinish={submit}
					layout='vertical'
					className='flex flex-col justify-between h-full'
				>
					<Spin spinning={isFetchingGroupCharacteristic}>
						<div className='flex flex-col gap-[20px]'>
							<Form.Item
								name='name'
								label={translate('group.characteristic.edit.name')}
								required
								className='w-full 650:w-1/2'
							>
								<Input size='large' type='text' />
							</Form.Item>

							<Form.Item name='description' label={translate('group.characteristic.edit.description')}>
								<Input.TextArea rows={4} />
							</Form.Item>
						</div>
					</Spin>

					<Form.Item className='flex justify-end mt-10'>
						<Button
							disabled={isSubmitting || isFetchingGroupCharacteristic}
							size='large'
							block
							type='primary'
							htmlType='submit'
						>
							{translate('group.characteristic.edit.save')}
						</Button>
					</Form.Item>
				</Form>
			</Main>
		</React.Fragment>
	);
};

export default GroupCharacteristicEdit;
