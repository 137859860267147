import { ReactNode } from 'react';

interface MainProps {
	showBackground?: boolean;
	children: ReactNode;
}

export const Main = ({ showBackground = true, children }: MainProps): JSX.Element => {
	return (
		<div className={`flex flex-col flex-1 min-w-0 box-border rounded-xl p-16 ${showBackground ? 'bg-white shadow-immerp' : ''}`}>
			{children}
		</div>
	);
};
