import { Button, Input, Spin, Table, Tag, notification } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import { TableProps } from 'antd/lib';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Head } from '../../../Component/Head/Head';
import Icon from '../../../Component/Icon/Icon';
import { Main } from '../../../Component/Main/Main';
import { Side } from '../../../Component/Side/Side';
import { ArticleListDto, GetArticlesListResponseDto } from '../../../Dto/Response/GetArticlesListResponseDto';
import useDebounce from '../../../Hook/useDebounce';
import useTranslate from '../../../Hook/useTranslate';
import { TableSorter } from '../../../Interface/TableSorter';
import * as apiService from '../../../Service/ApiService';

const ArticleManage = (): JSX.Element => {
	const { translate } = useTranslate();
	const navigate = useNavigate();
	const [notificationApi, notificationApiContextHolder] = notification.useNotification();

	const [isFetchingArticles, setIsFetchingArticles] = useState<boolean>(false);

	const [articles, setArticles] = useState<ArticleListDto[]>([]);
	const [query, setQuery] = useState<string>('');
	const debounceQuery = useDebounce<string>(query, 500);

	const [currentPage, setCurrentPage] = useState<number>(1);
	const [pageSize] = useState<number>(5);
	const [total, setTotal] = useState<number>(0);
	const [tableSort, setTableSort] = useState<TableSorter>({
		field: 'updatedAt',
		order: 'descend',
	});

	useEffect(() => {
		const getGroupsCharacteristicList = async (): Promise<void> => {
			setIsFetchingArticles(true);

			try {
				const result = await apiService.makeRequest<GetArticlesListResponseDto>({
					route: 'article/list',
					method: 'POST',
					data: {
						query: debounceQuery,
						currentPage: currentPage,
						pageSize: pageSize,
						sortField: tableSort.field,
						sortOrder: tableSort.order,
					},
				});

				setArticles(result.articles);
				setTotal(result.total);
			} catch (error) {
				notificationApi.error({
					message: translate('error'),
					description: `${error}`,
				});

				console.error(error);
			} finally {
				setIsFetchingArticles(false);
			}
		};

		getGroupsCharacteristicList();
	}, [debounceQuery, currentPage, pageSize, tableSort]);

	const handlePageChange = (newPage: number): void => {
		if (newPage > 0 && newPage <= Math.ceil(total / pageSize)) {
			setCurrentPage(newPage);
		}
	};

	const handleTableChange: TableProps['onChange'] = (_, __, sorter) => {
		const { columnKey, order } = sorter as SorterResult<string>;

		setTableSort({
			field: Array.isArray(columnKey) ? columnKey[0] : columnKey,
			order: order,
		});
	};

	return (
		<React.Fragment>
			{notificationApiContextHolder}

			<Main>
				<Spin spinning={isFetchingArticles}>
					<Head
						title={translate('article.manage.title')}
						subtitle={translate('article.manage.subtitle')}
						createUrl='/article/create'
						createIcon='faPlus'
					/>

					<Table
						dataSource={articles}
						columns={[
							{
								title: translate('article.manage.table.name'),
								dataIndex: 'name',
								key: 'name',
								sorter: true,
							},
							{
								title: translate('article.manage.table.author'),
								dataIndex: 'author',
								key: 'author',
								sorter: true,
							},
							{
								title: translate('article.manage.table.status'),
								dataIndex: 'status',
								key: 'status',
								align: 'center',
								sorter: true,
								render: (_, record: any): JSX.Element => (
									<Tag bordered={false} color={record.status === 'draft' ? 'processing' : 'success'}>
										{record.status === 'draft' && translate('article.status.draft')}
										{record.status === 'published' && translate('article.status.published')}
									</Tag>
								),
							},
							{
								title: translate('article.manage.table.published_date'),
								dataIndex: 'publishedDate',
								key: 'publishedDate',
								width: '200px',
								sorter: true,
								render: (_, record: any) => <span>{dayjs(record.publishedDate).format('L')}</span>,
							},
							{
								title: translate('article.manage.table.updated_at'),
								dataIndex: 'updatedAt',
								key: 'updatedAt',
								sorter: true,
								defaultSortOrder: 'descend',
								render: (_, record: any) => <span>{dayjs(record.updatedAt).format('L LTS')}</span>,
							},
							{
								title: translate('article.manage.table.action'),
								key: 'action',
								width: '0px',
								fixed: 'right',
								render: (_, record: any) => (
									<Button type='text' onClick={() => navigate(`/article/edit/${record.uuid}`)}>
										<Icon icon='faPen' color='#71717A' />
									</Button>
								),
							},
						]}
						rowKey='uuid'
						size='middle'
						bordered
						scroll={{ x: true }}
						onChange={handleTableChange}
						pagination={{
							showSizeChanger: false,
							current: currentPage,
							pageSize: pageSize,
							total: total,
							showTotal: (total) => (
								<Tag bordered={false} color='processing'>
									{translate('table.total', { total: total })}
								</Tag>
							),
							onChange: handlePageChange,
							size: 'default',
						}}
					/>
				</Spin>
			</Main>

			<Side>
				<Head title={translate('filters')} titleSize='18px' titleWeight='500' />

				<Input
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => setQuery(event.target.value)}
					prefix={<Icon icon='faSearch' color='#3F3F46' />}
					placeholder={translate('search')}
					size='large'
				/>
			</Side>
		</React.Fragment>
	);
};

export default ArticleManage;
