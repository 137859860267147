import { Drawer, FloatButton } from 'antd';
import React, { ReactNode, useState } from 'react';
import Icon from '../Icon/Icon';

export const Side = ({ children }: { children: ReactNode }): JSX.Element => {
	const [toogleDrawer, setToggleDrawer] = useState(false);

	return (
		<React.Fragment>
			<div className='sticky hidden 1k450:flex flex-col flex-grow-0 flex-shrink-0 top-[40px] w-[400px] h-[calc(100vh-80px)] py-12 box-border'>
				{children}
			</div>

			<FloatButton
				icon={<Icon icon='faSliders' color='#FFFFFF' />}
				onClick={(): void => setToggleDrawer(!toogleDrawer)}
				type='primary'
				className='1k450:hidden'
			/>

			<Drawer onClose={(): void => setToggleDrawer(!toogleDrawer)} open={toogleDrawer}>
				{children}
			</Drawer>
		</React.Fragment>
	);
};
