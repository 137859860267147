import { Input, Segmented } from 'antd';
import React, { useState } from 'react';
import { Head } from '../../Component/Head/Head';
import Icon from '../../Component/Icon/Icon';
import { Main } from '../../Component/Main/Main';
import { Side } from '../../Component/Side/Side';
import useTranslate from '../../Hook/useTranslate';
import CharacteristicManage from './CharacteristicManage/CharacteristicManage';
import GroupCharacteristicManage from './GroupCharacteristicManage/GroupCharacteristicManage';

const CharacteristicWrapper = (): JSX.Element => {
	const { translate } = useTranslate();

	const [segmentedValue, setSegmentedValue] = useState('characteristic');

	const options: { label: string; value: string }[] = [
		{
			label: translate('characteristic.segmented.characteristic'),
			value: 'characteristic',
		},
		{
			label: translate('characteristic.segmented.group'),
			value: 'group',
		},
	];

	const renderContent = (value: string): JSX.Element => {
		switch (value) {
			case 'characteristic':
				return <CharacteristicManage />;
			case 'group':
				return <GroupCharacteristicManage />;
			default:
				return null;
		}
	};

	return (
		<React.Fragment>
			<Main>
				<div className='flex flex-col'>
					<div className='flex justify-center mb-16'>
						<Segmented size='large' onChange={setSegmentedValue} options={options} />
					</div>

					{renderContent(segmentedValue)}
				</div>
			</Main>

			<Side>
				<Head title={translate('filters')} titleSize='18px' titleWeight='500' />

				<Input
					//onChange={(event: React.ChangeEvent<HTMLInputElement>) => setQuery(event.target.value)}
					prefix={<Icon icon='faSearch' color='#3F3F46' />}
					placeholder={translate('search')}
					size='large'
				/>
			</Side>
		</React.Fragment>
	);
};

export default CharacteristicWrapper;
