import React from 'react';

interface TimelineItem {
	date: React.ReactNode;
	content: React.ReactNode;
}

interface TimelineProps {
	items: TimelineItem[];
}

export const Timeline = ({ items }: TimelineProps): JSX.Element => {
	return (
		<div className='flex flex-col gap-4'>
			{items.map((item, index) => (
				<div key={index} className='flex items-stretch gap-4'>
					<div className='flex items-center'>{item.date}</div>

					<div className='relative flex items-center min-w-2.5'>
						<div className='top-4 z-10 flex justify-center items-center w-2.5 h-2.5 bg-[#5078FD] rounded-full'>
							<div className='w-1 h-1 bg-white rounded-full'></div>
						</div>

						{items.length > 1 && (
							<div
								className='absolute z-0 w-px bg-[#D4D4D8]'
								style={{
									top: index + 1 === 1 ? '50%' : index + 1 === items.length ? '-16px' : '',
									height: index === 0 || index + 1 === items.length ? 'calc(50% + 16px)' : 'calc(100% + 16px)',
									left: 'calc(50% - 0.5px)',
								}}
							></div>
						)}
					</div>

					{item.content}
				</div>
			))}
		</div>
	);
};
