import { Button, Input, Spin, Table, Tag, notification } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import { TableProps } from 'antd/lib';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Head } from '../../../Component/Head/Head';
import Icon from '../../../Component/Icon/Icon';
import { Main } from '../../../Component/Main/Main';
import { Side } from '../../../Component/Side/Side';
import { GetUsersListResponseDto, UserListDto } from '../../../Dto/Response/GetUsersListResponseDto';
import useDebounce from '../../../Hook/useDebounce';
import useTranslate from '../../../Hook/useTranslate';
import { TableSorter } from '../../../Interface/TableSorter';
import * as apiService from '../../../Service/ApiService';

const UserManage = (): JSX.Element => {
	const { translate } = useTranslate();
	const navigate = useNavigate();
	const [notificationApi, notificationApiContextHolder] = notification.useNotification();

	const [isFetchingUsers, setIsFetchingUsers] = useState<boolean>(false);

	const [users, setUsers] = useState<UserListDto[]>([]);
	const [query, setQuery] = useState<string>('');
	const debounceQuery = useDebounce<string>(query, 500);

	const [currentPage, setCurrentPage] = useState<number>(1);
	const [pageSize] = useState<number>(5);
	const [total, setTotal] = useState<number>(0);
	const [tableSort, setTableSort] = useState<TableSorter>({
		field: 'updatedAt',
		order: 'descend',
	});

	useEffect(() => {
		const getUsersList = async (): Promise<void> => {
			setIsFetchingUsers(true);

			try {
				const result = await apiService.makeRequest<GetUsersListResponseDto>({
					route: 'user/list',
					method: 'POST',
					data: {
						query: debounceQuery,
						currentPage: currentPage,
						pageSize: pageSize,
						sortField: tableSort.field,
						sortOrder: tableSort.order,
					},
				});

				setUsers(result.users);
				setTotal(result.total);
			} catch (error) {
				notificationApi.error({
					message: translate('error'),
					description: `${error}`,
				});

				console.error(error);
			} finally {
				setIsFetchingUsers(false);
			}
		};

		getUsersList();
	}, [debounceQuery, currentPage, pageSize, tableSort]);

	const handlePageChange = (newPage: number): void => {
		if (newPage > 0 && newPage <= Math.ceil(total / pageSize)) {
			setCurrentPage(newPage);
		}
	};

	const handleTableChange: TableProps['onChange'] = (_, __, sorter): void => {
		const { columnKey, order } = sorter as SorterResult<string>;

		setTableSort({
			field: Array.isArray(columnKey) ? columnKey[0] : columnKey,
			order: order,
		});
	};

	return (
		<React.Fragment>
			{notificationApiContextHolder}

			<Main>
				<Spin spinning={isFetchingUsers}>
					<Head
						title={translate('user.manage.title')}
						subtitle={translate('user.manage.subtitle')}
						createUrl='/admin/user/create'
						createIcon='faPlus'
					/>

					<Table
						dataSource={users}
						columns={[
							{
								title: translate('user.manage.table.lastname'),
								dataIndex: 'lastname',
								key: 'lastname',
								sorter: true,
							},
							{
								title: translate('user.manage.table.firstname'),
								dataIndex: 'firstname',
								key: 'firstname',
								sorter: true,
							},
							{
								title: translate('user.manage.table.email'),
								dataIndex: 'email',
								key: 'email',
								sorter: true,
							},
							{
								title: translate('user.manage.table.global_role'),
								dataIndex: 'globalRole',
								key: 'globalRole',
								align: 'center',
								sorter: true,
								render: (_, record: any) => (
									<Tag
										bordered={false}
										color={
											record.globalRole === 'admin'
												? 'error'
												: record.globalRole === 'user'
												? 'processing'
												: 'success'
										}
									>
										{record.globalRole === 'admin' && translate('user.manage.table.global_role.admin')}
										{record.globalRole === 'user' && translate('user.manage.table.global_role.user')}
										{record.globalRole === 'external' && translate('user.manage.table.global_role.external')}
									</Tag>
								),
							},
							{
								title: translate('user.manage.table.teams'),
								dataIndex: 'teamCount',
								key: 'teamCount',
								align: 'center',
								sorter: true,
								render: (_, record: any) => (
									<Tag bordered={false} color='processing'>
										{record.teamCount}
									</Tag>
								),
							},
							{
								title: translate('user.manage.table.updated_at'),
								dataIndex: 'updatedAt',
								key: 'updatedAt',
								sorter: true,
								defaultSortOrder: 'descend',
								render: (_, record: any) => <span>{dayjs(record.updatedAt).format('L LTS')}</span>,
							},
							{
								title: translate('user.manage.table.action'),
								key: 'action',
								width: '0px',
								fixed: 'right',
								render: (_, record: any) => (
									<Button type='text' onClick={() => navigate(`/admin/user/edit/${record.uuid}`)}>
										<Icon icon='faPen' color='#71717A' />
									</Button>
								),
							},
						]}
						rowKey='uuid'
						size='middle'
						bordered
						scroll={{ x: true }}
						onChange={handleTableChange}
						pagination={{
							showSizeChanger: false,
							current: currentPage,
							pageSize: pageSize,
							total: total,
							showTotal: (total) => (
								<Tag bordered={false} color='processing'>
									{translate('table.total', { total: total })}
								</Tag>
							),
							onChange: handlePageChange,
							size: 'default',
						}}
					/>
				</Spin>
			</Main>

			<Side>
				<Head title={translate('filters')} titleSize='18px' titleWeight='500' />

				<Input
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => setQuery(event.target.value)}
					prefix={<Icon icon='faSearch' color='#3F3F46' />}
					placeholder={translate('search')}
					size='large'
				/>
			</Side>
		</React.Fragment>
	);
};

export default UserManage;
