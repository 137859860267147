import React, { useEffect, useState } from 'react';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import { LoginResponseDto } from '../Dto/Response/LoginResponseDto';
import * as apiService from '../Service/ApiService';
import { UserType } from '../Type/UserType';

interface SessionContextInterface {
	user: UserType | null | undefined;
	loginUser: (
		token: string,
		uuid: string,
		lastname: string,
		firstname: string,
		email: string,
		globalRole: string,
		teams: { uuid: string; name: string }[],
	) => void;
	logoutUser: () => void;
	mode: 'team' | 'admin';
	setMode: (value: 'team' | 'admin') => void;
}

export const useSessionContextValue = (): SessionContextInterface => {
	const location = useLocation();
	const navigate: NavigateFunction = useNavigate();

	const [user, setUser] = useState<UserType | null | undefined>(undefined);
	const [mode, setMode] = useState<'team' | 'admin' | null>(null);

	useEffect(() => {
		const fetchData = async (): Promise<void> => {
			try {
				const result = await apiService.makeRequest<LoginResponseDto>({
					route: 'authentication/me',
					method: 'GET',
				});

				setUser(result);
			} catch (error) {
				setUser(null);
			}
		};

		fetchData();
	}, []);

	useEffect(() => {
		if (location.pathname.includes('admin')) {
			setMode('admin');
		} else {
			setMode('team');
		}
	}, [location.pathname]);

	useEffect(() => {
		if (user === null) navigate('/login');
	}, [user, navigate]);

	const loginUser = (
		token: string,
		uuid: string,
		lastname: string,
		firstname: string,
		email: string,
		globalRole: string,
		teams: { uuid: string; name: string }[],
	): void => {
		setUser({
			uuid: uuid,
			lastname: lastname,
			firstname: firstname,
			email: email,
			globalRole: globalRole,
			teams: teams,
		});

		localStorage.setItem('token', token);
		localStorage.setItem('currentTeam', teams[0].uuid);
	};

	const logoutUser = (): void => {
		localStorage.removeItem('token');
		localStorage.removeItem('currentTeam');
		setUser(null);
	};

	return {
		user,
		loginUser,
		logoutUser,
		mode,
		setMode,
	};
};

const SessionContext = React.createContext<SessionContextInterface>({
	user: null,
	loginUser: () => {
		throw new Error('Bad call');
	},
	logoutUser: () => {
		throw new Error('Bad call');
	},
	mode: null,
	setMode: () => {
		throw new Error('Bad call');
	},
});

export default SessionContext;
