import { Type } from 'class-transformer';
import { IsArray, IsOptional, IsString, Matches, ValidateNested } from 'class-validator';

export class PropertyImportUpdatedV1ContextDto {
	@IsArray()
	@Type(() => PropertyImportUpdatedCharacteristicV1ContextDto)
	@ValidateNested({ each: true })
	public characteristics: PropertyImportUpdatedCharacteristicV1ContextDto[];
}

export class PropertyImportUpdatedCharacteristicV1ContextDto {
	@IsString()
	public name: string;

	@IsString()
	@Matches('alphanumeric' || 'specific')
	public type: string;

	@IsOptional()
	public oldValue: string | number | null;

	@IsOptional()
	public newValue: string | number | null;
}
