import { ConfigProvider } from 'antd';
import SessionContext, { useSessionContextValue } from './Context/SessionContext';

import fr_FR from 'antd/locale/fr_FR';
import Routing from './Routing/Routing';
import { Theme } from './theme';

const App = (): JSX.Element => {
	const sessionContextValue = useSessionContextValue();

	return (
		<ConfigProvider locale={fr_FR} theme={Theme}>
			<SessionContext.Provider value={sessionContextValue}>
				<Routing />
			</SessionContext.Provider>
		</ConfigProvider>
	);
};

export default App;
