import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/fr';
import * as yaml from 'js-yaml';
import React, { useEffect, useState } from 'react';
import LanguageContext from './LanguageContext';

import localizedFormat from 'dayjs/plugin/localizedFormat';
dayjs.extend(localizedFormat);

interface LanguageContextProviderProps {
	children: React.ReactNode;
}

interface Languages {
	code: string;
	label: string;
	available: boolean;
}

interface Translations {
	[key: string]: string;
}

interface Language extends Languages {
	translations: Translations;
}

const languages: Languages[] = [
	{ code: 'fr_FR', label: 'Français', available: true },
	{ code: 'en_GB', label: 'English', available: true },
];

const storedLanguage = localStorage.getItem('language') as keyof typeof languages | null;

const preferredLanguage = (storedLanguage && languages.find((lang) => lang.code === storedLanguage)) || null;
const navigatorLanguage = languages.find((lang) => lang.code === navigator.language) || null;
const defaultLanguage = languages[0];

const selectedLanguage = preferredLanguage || navigatorLanguage || defaultLanguage;

const LanguageContextProvider: React.FC<LanguageContextProviderProps> = ({ children }) => {
	const [language, setLanguage] = useState<Language>({
		code: selectedLanguage.code,
		label: selectedLanguage.label,
		available: selectedLanguage.available,
		translations: null,
	});

	useEffect(() => {
		(async (): Promise<void> => {
			try {
				const dateCode = selectedLanguage.code.split('_')[0];
				dayjs.locale(dateCode);

				// eslint-disable-next-line @typescript-eslint/no-var-requires
				const response = await fetch(require(`../Translation/${selectedLanguage.code}.yaml`));
				const rawYaml = await response.text();

				const translations: Translations = yaml.load(rawYaml) as Translations;

				setLanguage((prev) => {
					return { ...prev, translations };
				});
			} catch (error) {
				console.error('Error while retrieving YAML data:', error);
			}
		})();
	}, []);

	const setLanguageCode = (code: string): void => {
		const dateCode = code.split('_')[0];
		dayjs.locale(dateCode);

		const wantedLanguage = languages.find((lang) => lang.code === code);

		if (wantedLanguage) {
			(async (): Promise<void> => {
				try {
					// eslint-disable-next-line @typescript-eslint/no-var-requires
					const response = await fetch(require(`../Translation/${wantedLanguage.code}.yaml`));
					const rawYaml = await response.text();

					const translations: Translations = yaml.load(rawYaml) as Translations;

					const newLanguage: Language = {
						code: wantedLanguage.code,
						label: wantedLanguage.label,
						available: wantedLanguage.available,
						translations: translations,
					};

					setLanguage(newLanguage);
				} catch (error) {
					console.error('Error while retrieving YAML data:', error);
				}
			})();
		}
	};

	return (
		<LanguageContext.Provider value={{ language, setLanguageCode, languages }}>
			{language.translations !== null ? children : []}
		</LanguageContext.Provider>
	);
};

export default LanguageContextProvider;
