import { Button, Divider, Form, Input, Popconfirm, Select, Spin, message, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import slugify from 'slugify';
import { Head } from '../../../Component/Head/Head';
import Icon from '../../../Component/Icon/Icon';
import { Main } from '../../../Component/Main/Main';
import useDebounce from '../../../Hook/useDebounce';
import useTranslate from '../../../Hook/useTranslate';
import * as apiService from '../../../Service/ApiService';
import { SortableList } from '../_Component/SortableList';
import { UserType } from '../_Type/UserType';

const { Option } = Select;

const UserListCreate = (): JSX.Element => {
	const { translate } = useTranslate();

	const navigate = useNavigate();
	const [userListCreateForm] = Form.useForm();
	const [notificationApi, notificationApiContextHolder] = notification.useNotification();

	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [isFetchingUsers, setIsFetchingUsers] = useState<boolean>(false);

	const [query, setQuery] = useState<string>('');
	const debounceQuery = useDebounce<string>(query, 500);

	const [users, setUsers] = useState<UserType[]>([]);
	const [selectedUsers, setSelectedUsers] = useState<UserType[]>([]);

	const updateSlug = (value: string): void => {
		userListCreateForm.setFieldsValue({
			slug: slugify(value, { lower: true }),
		});
	};

	const getUsersList = async (): Promise<void> => {
		setIsFetchingUsers(true);

		try {
			const result = await apiService.makeRequest<any>({
				route: 'user/list',
				method: 'POST',
				data: {
					query: debounceQuery,
					currentPage: 1,
					pageSize: 20,
				},
			});

			setUsers(result.users);
		} catch (error) {
			notificationApi.error({
				message: translate('error'),
				description: `${error}`,
			});

			console.error(error);
		} finally {
			setIsFetchingUsers(false);
		}
	};

	useEffect(() => {
		getUsersList();
	}, [debounceQuery]);

	const handleSearchUserSelect = (value: string): void => {
		const user = users.find((user: UserType) => user.uuid === value);

		setSelectedUsers((prevSelected: UserType[]) => {
			const alreadySelected = prevSelected.some((selectedUser) => selectedUser.uuid === value);
			if (!alreadySelected) {
				return [...prevSelected, user];
			}
			return prevSelected;
		});
	};

	const submit = async (values: any): Promise<void> => {
		setIsSubmitting(true);

		try {
			await apiService.makeRequest({
				route: 'user-list/create',
				method: 'POST',
				data: {
					name: values.name,
					slug: values.slug,
					users: selectedUsers.map((selectedUser) => ({
						uuid: selectedUser.uuid,
					})),
				},
			});

			message.success(translate('user_list.create.success'));

			navigate('/user-list/manage');
		} catch (error) {
			notificationApi.error({
				message: translate('error'),
				description: `${error}`,
			});

			console.error(error);
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<React.Fragment>
			{notificationApiContextHolder}

			<Spin spinning={isSubmitting} fullscreen />

			<Main>
				<Head title={translate('user_list.create.title')} subtitle={translate('user_list.create.subtitle')} />

				<Form form={userListCreateForm} onFinish={submit} layout='vertical' className='flex ${} flex-col justify-between h-full'>
					<div className='flex flex-col'>
						<div className='flex flex-col gap-[20px]'>
							<div className='flex flex-col 650:flex-row gap-[20px]'>
								<Form.Item name='name' label={translate('user_list.create.name')} required className='w-full 650:w-1/2'>
									<Input
										size='large'
										type='text'
										autoComplete='off'
										onChange={(e: React.ChangeEvent<HTMLInputElement>): void => updateSlug(e.target.value)}
									/>
								</Form.Item>

								<Form.Item name='slug' label={translate('user_list.create.slug')} required className='w-full 650:w-1/2'>
									<Input size='large' type='text' autoComplete='off' />
								</Form.Item>
							</div>

							<Form.Item label={translate('user_list.create.add_user')} className='w-full'>
								<Select
									showSearch
									searchValue={query}
									loading={isFetchingUsers}
									filterOption={false}
									onSearch={(event): void => setQuery(event)}
									onSelect={(event): void => handleSearchUserSelect(event)}
									onBlur={(): void => setQuery(query)}
									value={null}
									size='large'
								>
									{users.map((user: UserType) => (
										<Option key={user.uuid} value={user.uuid}>
											{user.firstname} {user.lastname}
										</Option>
									))}
								</Select>
							</Form.Item>
						</div>

						<Divider orientation='left'>
							<span className='text-lg font-medium'>{translate('user_list.create.users')}</span>
						</Divider>

						<div className='flex flex-col gap-[10px]'>
							<SortableList
								items={selectedUsers}
								onChange={setSelectedUsers}
								renderItem={(item: UserType): JSX.Element => (
									<SortableList.Item uuid={item.uuid}>
										<div className='flex items-center h-full gap-4'>
											<div className='flex h-8'>
												<SortableList.DragHandle />
											</div>

											<div className='min-w-36 h-24'>
												<div
													className='w-full h-full flex justify-center items-center rounded-lg'
													style={{
														background: `url(${item.imageUrl})`,
														backgroundRepeat: 'no-repeat',
														backgroundSize: 'cover',
														backgroundPosition: 'center',
													}}
												>
													{!item.imageUrl && <Icon icon='faImage' size={40} color='#E4E4E7' />}
												</div>
											</div>

											<div className='flex flex-col gap-3'>
												<span>
													{item.firstname} {item.lastname}
												</span>

												<div>
													{item.visibleOnWeb ? (
														<span className='inline-flex justify-center items-center gap-1 bg-[#E9F7F1] text-[#3AB795] text-xs px-2 py-1 rounded-lg'>
															<Icon icon='faEye' size={10} color='#3AB795' />
															<span>{translate('property_list.create.visible_on_web.yes')}</span>
														</span>
													) : (
														<span className='inline-flex justify-center items-center gap-1 bg-[#FFE6EA] text-[#F31260] text-xs px-2 py-1 rounded-lg'>
															<Icon icon='faEyeSlash' size={10} color='#F31260' />
															<span>{translate('property_list.create.visible_on_web.no')}</span>
														</span>
													)}
												</div>
											</div>
										</div>

										<div className='flex justify-end items-center gap-3 h-8'>
											<div className='flex items-center justify-center h-full rounded-lg cursor-pointer hover:bg-[rgba(0,_0,_0,_0.05)] transition-all'>
												<Popconfirm
													placement='topRight'
													icon={
														<div className='mr-2'>
															<Icon icon='faWarning' color='#F31260' size={15} />
														</div>
													}
													title={translate('property_list.create.delete_item')}
													okText={translate('property_list.create.confirm_delete_item')}
													okButtonProps={{
														danger: true,
													}}
													cancelText={translate('property_list.create.cancel_delete_item')}
													onConfirm={(): void =>
														setSelectedUsers((prevSelected: UserType[]) =>
															prevSelected.filter((prevUser) => prevUser.uuid !== item.uuid),
														)
													}
												>
													<Button ghost className='border-none px-[10px] h-full'>
														<Icon icon='faTrash' color='#F31260' />
													</Button>
												</Popconfirm>
											</div>
										</div>
									</SortableList.Item>
								)}
							/>
						</div>
					</div>

					<Form.Item className='flex justify-end mt-10'>
						<Button disabled={isSubmitting} size='large' block type='primary' htmlType='submit'>
							{translate('user_list.create.save')}
						</Button>
					</Form.Item>
				</Form>
			</Main>
		</React.Fragment>
	);
};

export default UserListCreate;
