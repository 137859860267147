import { Button, Table, Tag, notification } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import { TableProps } from 'antd/lib';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Head } from '../../../Component/Head/Head';
import Icon from '../../../Component/Icon/Icon';
import { Main } from '../../../Component/Main/Main';
import { GetUserListsResponseDto, UserListDto } from '../../../Dto/Response/GetUserListsResponseDto';
import useDebounce from '../../../Hook/useDebounce';
import useTranslate from '../../../Hook/useTranslate';
import { TableSorter } from '../../../Interface/TableSorter';
import * as apiService from '../../../Service/ApiService';

const UserListManage = (): JSX.Element => {
	const { translate } = useTranslate();
	const navigate = useNavigate();
	const [notificationApi, notificationApiContextHolder] = notification.useNotification();

	const [isFetchingUserList, setIsFetchingUserList] = useState<boolean>(false);

	const [userLists, setUserLists] = useState<UserListDto[]>([]);
	const [query, setQuery] = useState<string>('');
	const debounceQuery = useDebounce<string>(query, 500);

	const [currentPage, setCurrentPage] = useState<number>(1);
	const [pageSize] = useState<number>(10);
	const [total, setTotal] = useState<number>(0);
	const [tableSort, setTableSort] = useState<TableSorter>({
		field: 'updatedAt',
		order: 'descend',
	});

	useEffect(() => {
		const getUserListsList = async (): Promise<void> => {
			setIsFetchingUserList(true);

			try {
				const result = await apiService.makeRequest<GetUserListsResponseDto>({
					route: 'user-list/list',
					method: 'POST',
					data: {
						query: debounceQuery,
						currentPage: currentPage,
						pageSize: pageSize,
						sortField: tableSort.field,
						sortOrder: tableSort.order,
					},
				});

				setUserLists(result.userLists);
				setTotal(result.total);
			} catch (error) {
				notificationApi.error({
					message: translate('error'),
					description: `${error}`,
				});

				console.error(error);
			} finally {
				setIsFetchingUserList(false);
			}
		};

		getUserListsList();
	}, [debounceQuery, currentPage, pageSize]);

	const handlePageChange = (newPage: number): void => {
		if (newPage > 0 && newPage <= Math.ceil(total / pageSize)) {
			setCurrentPage(newPage);
		}
	};

	const handleTableChange: TableProps['onChange'] = (_, __, sorter) => {
		const { columnKey, order } = sorter as SorterResult<string>;

		setTableSort({
			field: Array.isArray(columnKey) ? columnKey[0] : columnKey,
			order: order,
		});
	};

	return (
		<React.Fragment>
			{notificationApiContextHolder}

			<Main>
				<Head
					title={translate('user_list.manage.title')}
					subtitle={translate('user_list.manage.subtitle')}
					createUrl='/user-list/create'
					createIcon='faPlus'
				/>

				<Table
					dataSource={userLists}
					columns={[
						{
							title: translate('user_list.manage.table.name'),
							dataIndex: 'name',
							key: 'name',
						},
						{
							title: translate('user_list.manage.table.nb_users'),
							dataIndex: 'nbUsers',
							key: 'nbUsers',
							align: 'center',
							render: (_, record: any) => (
								<Tag bordered={false} color='processing'>
									{record.nbUsers}
								</Tag>
							),
						},
						{
							title: translate('user_list.manage.table.updated_at'),
							dataIndex: 'updatedAt',
							key: 'updatedAt',
							sorter: true,
							render: (_, record: any) => <span>{dayjs(record.updatedAt).format('L LTS')}</span>,
						},
						{
							title: translate('user_list.manage.table.action'),
							key: 'action',
							width: '0px',
							fixed: 'right',
							render: (_, record: any) => (
								<Button type='text' onClick={(): void => navigate(`/user-list/edit/${record.uuid}`)}>
									<Icon icon='faPen' color='#71717A' />
								</Button>
							),
						},
					]}
					rowKey='uuid'
					loading={isFetchingUserList}
					size='middle'
					bordered
					scroll={{ x: true }}
					onChange={handleTableChange}
					pagination={{
						showSizeChanger: false,
						current: currentPage,
						pageSize: pageSize,
						total: total,
						showTotal: (total) => (
							<Tag bordered={false} color='processing'>
								{translate('table.total', { total: total })}
							</Tag>
						),
						onChange: handlePageChange,
						size: 'default',
					}}
				/>
			</Main>
		</React.Fragment>
	);
};

export default UserListManage;
