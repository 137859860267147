import { createContext } from 'react';

interface Languages {
	code: string;
	label: string;
	available: boolean;
}

interface Translations {
	[key: string]: string;
}

interface Language extends Languages {
	translations: Translations;
}

type LanguageContextType = {
	language: Language;
	setLanguageCode: (code: string) => void;
	languages: Languages[];
};

const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

export default LanguageContext;
