import { Button, Form, Input, Spin, message, notification } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Head } from '../../../Component/Head/Head';
import { Main } from '../../../Component/Main/Main';
import useTranslate from '../../../Hook/useTranslate';
import * as apiService from '../../../Service/ApiService';

const GroupCharacteristicCreate = (): JSX.Element => {
	const { translate } = useTranslate();
	const navigate = useNavigate();
	const [groupCharacteristicCreateForm] = Form.useForm();
	const [notificationApi, notificationApiContextHolder] = notification.useNotification();

	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

	const submit = async (values: any): Promise<void> => {
		setIsSubmitting(true);

		try {
			await apiService.makeRequest({
				route: 'group-characteristic/create',
				method: 'POST',
				data: {
					...values,
				},
			});

			message.success(translate('group.characteristic.create.success'));

			navigate('/admin/characteristic/manage');
		} catch (error) {
			notificationApi.error({
				message: translate('error'),
				description: `${error}`,
			});

			console.error(error);
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<React.Fragment>
			{notificationApiContextHolder}

			<Spin spinning={isSubmitting} fullscreen />

			<Main>
				<Head
					title={translate('group.characteristic.create.title')}
					subtitle={translate('group.characteristic.create.subtitle')}
					marginBottom='64px'
				/>

				<Form
					form={groupCharacteristicCreateForm}
					onFinish={submit}
					layout='vertical'
					className='flex flex-col justify-between h-full'
				>
					<div className='flex flex-col gap-[20px]'>
						<Form.Item name='name' label={translate('group.characteristic.create.name')} required className='w-full 650:w-1/2'>
							<Input size='large' type='text' />
						</Form.Item>

						<Form.Item name='description' label={translate('group.characteristic.create.description')}>
							<Input.TextArea rows={4} />
						</Form.Item>
					</div>

					<Form.Item className='flex justify-end mt-10'>
						<Button disabled={isSubmitting} size='large' block type='primary' htmlType='submit'>
							{translate('group.characteristic.create.save')}
						</Button>
					</Form.Item>
				</Form>
			</Main>
		</React.Fragment>
	);
};

export default GroupCharacteristicCreate;
