import { Button, Form, Input, Segmented, Spin, Upload, UploadProps, message } from 'antd';
import { GetProp } from 'antd/lib';
import JoditEditor from 'jodit-react';
import { useRef, useState } from 'react';
import Icon from '../../../Component/Icon/Icon';
import { UploadImageResponseDto } from '../../../Dto/Response/UploadImageResponseDto';
import useTranslate from '../../../Hook/useTranslate';
import * as apiService from '../../../Service/ApiService';

type itemContent = {
	text?: string;
	imageUuid?: string;
	imageUrl?: string;
	imageCaption?: string;
};

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

export const CreateItem = ({ addItem }: { addItem: (type: 'paragraph' | 'image', content: itemContent) => void }): JSX.Element => {
	const editor = useRef(null);
	const { translate } = useTranslate();

	const [isUploading, setIsUploading] = useState<boolean>(false);

	const [segmentedValue, setSegmentedValue] = useState<'paragraph' | 'image'>('paragraph');
	const [imageUrl, setImageUrl] = useState<string | null>(null);
	const [imageUuid, setImageUuid] = useState<string | null>(null);
	const [imageCaption, setImageCaption] = useState<string>('');

	const config = {
		language: localStorage.getItem('language').slice(0, -3),
		readonly: false,
		spellcheck: true,
		addNewLine: false,
		showCharsCounter: false,
		showWordsCounter: false,
		showXPathInStatusbar: false,
		askBeforePasteHTML: false,
		askBeforePasteFromWord: false,
		defaultActionOnPaste: 'insert_clear_html' as any,
		buttons: ['undo', 'redo', '|', 'bold', 'italic', 'underline', 'strikethrough', '|', 'superscript', 'subscript', '|', 'ul', 'ol'],
		controls: {
			ul: {
				list: undefined as [],
			},
			ol: {
				list: undefined as [],
			},
		},
	};

	const beforeUpload = (file: FileType): boolean => {
		const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

		if (!isJpgOrPng) {
			message.error(translate('article.create.image_restriction'));
			return;
		}

		return isJpgOrPng;
	};

	const handleFileChange = (info: any): void => {
		const file = info.file.originFileObj as FileType;

		const reader = new FileReader();

		reader.addEventListener('load', async () => {
			await uploadImage(reader.result as string);
		});

		reader.readAsDataURL(file);
	};

	const uploadImage = async (base64: string): Promise<void> => {
		try {
			setIsUploading(true);

			const result = await apiService.makeRequest<UploadImageResponseDto>({
				route: 'image/upload',
				method: 'POST',
				data: {
					base64,
				},
			});

			setImageUuid(result.image.uuid);
			setImageUrl(result.image.url);

			setIsUploading(false);
		} catch (error) {
			setIsUploading(false);

			console.error(error);
		}
	};

	const handleAddImage = (): void => {
		let content = {};

		if (segmentedValue === 'paragraph') {
			content = {
				text: editor.current.value,
			};
		} else if (segmentedValue === 'image') {
			content = {
				imageUuid: imageUuid,
				imageCaption: imageCaption,
				imageUrl: imageUrl,
			};
		}

		addItem(segmentedValue, content);
	};

	return (
		<Spin spinning={isUploading}>
			<div className='flex flex-col gap-6 px-5 py-4'>
				<div className='text-[22px] font-bold'>{translate('article.create.new_item')}</div>

				<div className='flex justify-center items-center flex-col gap-6'>
					<Segmented
						value={segmentedValue}
						onChange={setSegmentedValue}
						size='large'
						options={[
							{
								label: translate('article.create.mode_paragraph'),
								icon: <Icon icon='faParagraph' />,
								value: 'paragraph',
							},
							{
								label: translate('article.create.mode_image'),
								icon: <Icon icon='faImage' />,
								value: 'image',
							},
						]}
					/>

					{segmentedValue === 'paragraph' && (
						<div className='w-full'>
							<JoditEditor ref={editor} config={config} value={null} />
						</div>
					)}

					{segmentedValue === 'image' && (
						<Form layout='vertical' className='flex justify-between items-center gap-5 h-full w-full'>
							<Form.Item label className='flex items-center'>
								<Upload
									listType='picture-card'
									showUploadList={false}
									customRequest={(): void => undefined}
									beforeUpload={beforeUpload}
									onChange={handleFileChange}
								>
									{imageUrl ? (
										<img src={imageUrl} alt='avatar' className='w-full' />
									) : (
										<button type='button' className='bg-transparent border-none'>
											<div>{translate('article.create.add_item.image')}</div>
										</button>
									)}
								</Upload>
							</Form.Item>

							<Form.Item label={translate('article.create.add_item.description')} className='w-full'>
								<Input.TextArea
									value={imageCaption}
									onChange={(event: React.ChangeEvent<HTMLTextAreaElement>): void => setImageCaption(event.target.value)}
									rows={4}
									showCount
									maxLength={255}
									style={{ resize: 'none' }}
								/>
							</Form.Item>
						</Form>
					)}
				</div>

				<div className='flex justify-end mt-4'>
					<Button disabled={isUploading} onClick={handleAddImage} size='large' type='primary'>
						{translate('article.create.save_create_item')}
					</Button>
				</div>
			</div>
		</Spin>
	);
};
