import { Button, Divider, Form, Input, Spin, Table, Tag, Tooltip, message, notification } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import { TableProps } from 'antd/lib';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Head } from '../../../Component/Head/Head';
import Icon from '../../../Component/Icon/Icon';
import { Main } from '../../../Component/Main/Main';
import { GetTeamByUuidResponseDto } from '../../../Dto/Response/GetTeamByUuidResponseDto';
import { GetUsersListByTeamUuidResponseDto, UserListByTeamDto } from '../../../Dto/Response/GetUsersListByTeamUuidResponseDto';
import useTranslate from '../../../Hook/useTranslate';
import { TableSorter } from '../../../Interface/TableSorter';
import * as apiService from '../../../Service/ApiService';

// const { confirm } = Modal;

const TeamEdit = (): JSX.Element => {
	const { uuid } = useParams();
	const { translate } = useTranslate();
	const navigate = useNavigate();
	const [teamEditForm] = Form.useForm();

	const [notificationApi, notificationApiContextHolder] = notification.useNotification();

	const [isFetchingUsersList, setIsFetchingUsersList] = useState<boolean>(false);
	const [isFetchingTeam, setIsFetchingTeam] = useState<boolean>(false);
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

	const [currentPage, setCurrentPage] = useState<number>(1);
	const [pageSize] = useState<number>(5);
	const [total, setTotal] = useState<number>(0);
	const [tableSort, setTableSort] = useState<TableSorter>({
		field: 'createdAt',
		order: 'descend',
	});

	const [users, setUsers] = useState<UserListByTeamDto[]>([]);

	useEffect(() => {
		const getUsersListByTeamUuid = async (): Promise<void> => {
			setIsFetchingUsersList(true);

			try {
				const result = await apiService.makeRequest<GetUsersListByTeamUuidResponseDto>({
					route: 'user/list-by-team',
					method: 'POST',
					data: {
						teamUuid: uuid,
						query: '',
						currentPage: currentPage,
						pageSize: pageSize,
						sortField: tableSort.field,
						sortOrder: tableSort.order,
					},
				});

				setUsers(result.users);
				setTotal(result.total);
			} catch (error) {
				notificationApi.error({
					message: translate('error'),
					description: `${error}`,
				});

				console.error(error);
			} finally {
				setIsFetchingUsersList(false);
			}
		};

		getUsersListByTeamUuid();
	}, [uuid, currentPage, pageSize, tableSort]);

	useEffect(() => {
		const getTeamByUuid = async (): Promise<void> => {
			setIsFetchingTeam(true);

			try {
				const result = await apiService.makeRequest<GetTeamByUuidResponseDto>({
					route: `team/${uuid}`,
					method: 'GET',
				});

				teamEditForm.setFieldsValue({
					name: result.name,
					email: result.email,
					phone: result.phone,
					line1: result.line1,
					additionalInfo: result.additionalInfo,
					city: result.city,
					country: result.country,
					postalCode: `${result.postalCode}`,
					roles: result.roles,
				});
			} catch (error) {
				notificationApi.error({
					message: translate('error'),
					description: `${error}`,
				});

				console.error(error);
			} finally {
				setIsFetchingTeam(false);
			}
		};

		getTeamByUuid();
	}, [uuid, teamEditForm]);

	const submit = async (values: any): Promise<void> => {
		setIsSubmitting(true);

		try {
			await apiService.makeRequest({
				route: `team/update/${uuid}`,
				method: 'POST',
				data: {
					...values,
				},
			});

			message.success(translate('team.edit.success'));

			navigate('/admin/team/manage');
		} catch (error) {
			notificationApi.error({
				message: translate('error'),
				description: `${error}`,
			});

			console.error(error);
		} finally {
			setIsSubmitting(false);
		}
	};

	// const showDeleteConfirm = () => {
	//     confirm({
	//         title: translate('team.delete'),
	//         icon: <Icon icon="faTrash" />,
	//         okText: translate('team.delete.confirm'),
	//         okType: 'danger',
	//         cancelText: translate('team.delete.cancel'),
	//         async onOk() {
	//             await handleDelete()
	//         },
	//         onCancel() { },
	//     });
	// };

	// const handleDelete = async () => {
	//     try {
	//         setIsLoading(true);

	//         await apiService.makeRequest({
	//             route: 'team',
	//             method: 'DELETE',
	//             data: {
	//                 uuid
	//             }
	//         });

	//         setIsLoading(false);

	//         navigate('/team/list');
	//     } catch (error) {
	//         setIsLoading(false);

	//         notificationApi.error({
	//             message: translate('error'),
	//             description: `${error}`,
	//         });

	//         console.error(error);
	//     }
	// };

	const handlePageChange = (newPage: number): void => {
		if (newPage > 0 && newPage <= Math.ceil(total / pageSize)) {
			setCurrentPage(newPage);
		}
	};

	const handleTableChange: TableProps['onChange'] = (_, __, sorter) => {
		const { columnKey, order } = sorter as SorterResult<string>;

		setTableSort({
			field: Array.isArray(columnKey) ? columnKey[0] : columnKey,
			order: order,
		});
	};

	const addRole = (): void => {
		teamEditForm.setFieldsValue({
			roles: [...teamEditForm.getFieldValue('roles'), { uuid: undefined, name: undefined, isRemovable: 1, userCount: '0' }],
		});
	};

	const removeRole = (key: number): void => {
		const roles = teamEditForm.getFieldValue('roles');

		roles.splice(key, 1);

		teamEditForm.setFieldsValue({
			roles: [...roles],
		});
	};

	return (
		<React.Fragment>
			{notificationApiContextHolder}

			<Spin spinning={isSubmitting} fullscreen />

			<Main>
				<Head title={translate('team.edit.title')} subtitle={translate('team.edit.subtitle')} marginBottom='64px' />

				<Form form={teamEditForm} onFinish={submit} layout='vertical' className='flex flex-col justify-between h-full'>
					<Spin spinning={isFetchingUsersList || isFetchingTeam}>
						<div className='flex flex-col gap-[20px]'>
							<div className='flex flex-col 650:flex-row gap-[20px]'>
								<Form.Item name='name' label={translate('team.edit.name')} required className='w-full 1k:w-1/2'>
									<Input size='large' type='text' />
								</Form.Item>
							</div>

							<Divider orientation='left'>
								<span className='text-lg font-medium'>{translate('team.edit.contact')}</span>
							</Divider>

							<div className='flex flex-col 650:flex-row gap-[20px]'>
								<Form.Item name='email' label={translate('team.edit.mail')} className='w-full 650:w-1/2'>
									<Input
										suffix={<Icon icon='faEnvelope' color='#A1A1AA' />}
										size='large'
										type='text'
										autoComplete='off'
									/>
								</Form.Item>

								<Form.Item name='phone' label={translate('team.edit.phone')} className='w-full 650:w-1/2'>
									<Input suffix={<Icon icon='faPhone' color='#A1A1AA' />} size='large' type='text' autoComplete='off' />
								</Form.Item>
							</div>

							<div className='flex flex-col 650:flex-row gap-[20px]'>
								<Form.Item name='line1' label={translate('team.edit.line1')} className='w-full 650:w-1/2'>
									<Input size='large' type='text' autoComplete='off' />
								</Form.Item>

								<Form.Item name='additionalInfo' label={translate('team.edit.additionalInfo')} className='w-full 650:w-1/2'>
									<Input size='large' type='text' autoComplete='off' />
								</Form.Item>
							</div>

							<div className='flex flex-col 650:flex-row gap-[20px]'>
								<Form.Item name='city' label={translate('team.edit.city')} className='w-full 650:w-1/2'>
									<Input size='large' type='text' autoComplete='off' />
								</Form.Item>

								<Form.Item name='country' label={translate('team.edit.country')} className='w-full 650:w-1/2'>
									<Input size='large' type='text' autoComplete='off' />
								</Form.Item>

								<Form.Item name='postalCode' label={translate('team.edit.postalCode')} className='w-full 650:w-1/2'>
									<Input size='large' type='text' autoComplete='off' />
								</Form.Item>
							</div>

							<Divider orientation='left'>
								<span className='text-lg font-medium'>{translate('team.edit.roles')}</span>
							</Divider>

							<Form.List name='roles'>
								{(fields): JSX.Element => (
									<React.Fragment>
										{fields.map(({ key, name, ...restField }) => {
											const roles = teamEditForm.getFieldValue('roles');
											const role = roles[key];

											return (
												<div key={`edit-role-name-${key}`} className='flex items-center gap-[20px]'>
													<Form.Item {...restField} name={[name, 'name']} className='w-full'>
														<Input disabled={role.isRemovable === 0} size='large' />
													</Form.Item>

													{role.isRemovable === 1 &&
														(role.userCount === '0' ? (
															<Button danger type='text' onClick={(): void => removeRole(key)}>
																<Icon icon='faTrash' color='#F31260' />
															</Button>
														) : (
															<Tooltip title={translate('team.edit.role.delete.warning')}>
																<Button type='link'>
																	<Icon icon='faWarning' color='#F5A524' />
																</Button>
															</Tooltip>
														))}
												</div>
											);
										})}

										<Form.Item>
											<Button type='dashed' onClick={(): void => addRole()} block icon={<Icon icon='faPlus' />}>
												{translate('team.edit.add_role')}
											</Button>
										</Form.Item>
									</React.Fragment>
								)}
							</Form.List>

							<Divider orientation='left' style={{ marginBottom: 0 }}>
								<span className='text-lg font-medium'>{translate('team.edit.users')}</span>
							</Divider>

							<span className='text-gray-400'>{translate('team.edit.users.description')}</span>

							<Table
								dataSource={users}
								columns={[
									{
										title: translate('team.edit.user.manage.lastname'),
										dataIndex: 'lastname',
										key: 'lastname',
										sorter: true,
									},
									{
										title: translate('team.edit.user.manage.firstname'),
										dataIndex: 'firstname',
										key: 'firstname',
										sorter: true,
									},
									{
										title: translate('team.edit.user.manage.email'),
										dataIndex: 'email',
										key: 'email',
										sorter: true,
									},
									{
										title: translate('team.edit.user.manage.role'),
										dataIndex: 'role',
										key: 'role',
										sorter: true,
										render: (_, record: any) => (
											<Tag bordered={false} color='processing'>
												{record.role}
											</Tag>
										),
									},
									{
										title: translate('team.edit.user.manage.created_at'),
										dataIndex: 'createdAt',
										key: 'createdAt',
										width: '200px',
										sorter: true,
										defaultSortOrder: 'descend',
										render: (_, record: any) => <span>{dayjs(record.createdAt).format('L LTS')}</span>,
									},
								]}
								rowKey='uuid'
								size='middle'
								bordered
								scroll={{ x: true }}
								onChange={handleTableChange}
								pagination={{
									current: currentPage,
									pageSize: pageSize,
									total: total,
									showTotal: (total) => (
										<Tag bordered={false} color='processing'>
											{translate('table.total', { total: total })}
										</Tag>
									),
									onChange: handlePageChange,
									size: 'default',
								}}
							/>
						</div>
					</Spin>

					{/* <div className="flex justify-between mt-10">
                        <Form.Item className="flex justify-end">
                            <Button danger size='large' onClick={showDeleteConfirm}>
                                {translate('team.delete')}
                            </Button>
                        </Form.Item> */}

					<Form.Item className='flex justify-end mt-10'>
						<Button
							disabled={isSubmitting || isFetchingUsersList || isFetchingTeam}
							size='large'
							type='primary'
							htmlType='submit'
						>
							{translate('team.edit.save')}
						</Button>
					</Form.Item>

					{/* </div> */}
				</Form>
			</Main>
		</React.Fragment>
	);
};

export default TeamEdit;
