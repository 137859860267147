import { Button } from 'antd';
import { plainToInstance } from 'class-transformer';
import React, { useEffect, useState } from 'react';
import { PropertyCharacteristicsUpdatedV1ContextDto } from '../../Dto/EventContext/PropertyCharacteristicsUpdatedV1ContextDto';
import { PropertyImageRelationCreatedV1ContextDto } from '../../Dto/EventContext/PropertyImageRelationCreatedV1ContextDto';
import { PropertyImageRelationDeletedV1ContextDto } from '../../Dto/EventContext/PropertyImageRelationDeletedV1ContextDto';
import { PropertyImportUpdatedV1ContextDto } from '../../Dto/EventContext/PropertyImportUpdatedV1ContextDto';
import { EventEnum } from '../../Enum/EventEnum';
import Icon from '../Icon/Icon';

interface RenderEventProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	event: any;
}

const RenderEvent = ({ event }: RenderEventProps): JSX.Element => {
	const [eventContent, setEventContent] = useState<JSX.Element | null>(null);

	const [propertyCharacteristicImportUpdatedV1ShowMore, setPropertyCharacteristicImportUpdatedV1ShowMore] = useState<boolean>(false);
	const [propertyCharacteristicUpdatedV1ShowMore, setPropertyCharacteristicUpdatedV1ShowMore] = useState<boolean>(false);

	useEffect(() => {
		const renderEventContent = async (): Promise<void> => {
			switch (event.code) {
				case EventEnum.PROPERTY_CREATED_V1: {
					setEventContent(<div className='font-semibold'>{event.message}</div>);

					break;
				}

				case EventEnum.PROPERTY_IMPORT_CREATED_V1: {
					setEventContent(<div className='font-semibold'>{event.message}</div>);

					break;
				}

				case EventEnum.PROPERTY_IMPORT_UPDATED_V1: {
					const context = JSON.parse(event.context);
					const transformedContext = plainToInstance(PropertyImportUpdatedV1ContextDto, context);

					setEventContent(
						<div className='flex flex-col gap-4'>
							<div className='font-semibold'>{event.message}</div>

							<div className='flex flex-col gap-2'>
								{transformedContext.characteristics
									.slice(0, propertyCharacteristicImportUpdatedV1ShowMore ? undefined : 4)
									.map((characteristic, index: number) => (
										<div key={index} className='flex items-center gap-4 bg-[#FAFAFA] rounded-lg px-4 py-2'>
											<div>{characteristic.name} :</div>
											<div className='text-[#C40E4D] line-through'>{characteristic.oldValue ?? 'Aucune valeur'}</div>
											<Icon icon='faChevronRight' />
											<div className='text-[#2E9277]'>{characteristic.newValue ?? 'Aucune valeur'}</div>
										</div>
									))}

								{transformedContext.characteristics.length > 4 && (
									<Button
										type='dashed'
										onClick={(): void => setPropertyCharacteristicImportUpdatedV1ShowMore((prev) => !prev)}
									>
										{propertyCharacteristicImportUpdatedV1ShowMore ? 'Voir moins' : 'Voir plus'}
									</Button>
								)}
							</div>
						</div>,
					);

					break;
				}

				case EventEnum.PROPERTY_CHARACTERISTICS_UPDATED_V1: {
					const context = JSON.parse(event.context);
					const transformedContext = plainToInstance(PropertyCharacteristicsUpdatedV1ContextDto, context);

					setEventContent(
						<React.Fragment>
							{transformedContext.characteristics
								.slice(0, propertyCharacteristicUpdatedV1ShowMore ? undefined : 4)
								.map((characteristic, index: number) => (
									<div key={index} className='flex items-center gap-4 bg-[#FAFAFA] rounded-lg px-4 py-2'>
										<div>{characteristic.name} :</div>
										<div className='text-[#C40E4D] line-through'>{characteristic.oldValue ?? 'Aucune valeur'}</div>
										<Icon icon='faChevronRight' />
										<div className='text-[#2E9277]'>{characteristic.newValue ?? 'Aucune valeur'}</div>
									</div>
								))}

							{transformedContext.characteristics.length > 4 && (
								<Button type='dashed' onClick={(): void => setPropertyCharacteristicUpdatedV1ShowMore((prev) => !prev)}>
									{propertyCharacteristicUpdatedV1ShowMore ? 'Voir moins' : 'Voir plus'}
								</Button>
							)}
						</React.Fragment>,
					);

					break;
				}

				case EventEnum.PROPERTY_IMAGE_RELATION_CREATED_V1: {
					const context = JSON.parse(event.context);
					const transformedContext = plainToInstance(PropertyImageRelationCreatedV1ContextDto, context);

					setEventContent(
						<div className='flex items-center gap-4'>
							<img src={transformedContext.image} alt='' width={50} height={50} className='rounded-xl' />

							<div className='text-[#2E9277]'>{event.message}</div>
						</div>,
					);

					break;
				}

				case EventEnum.PROPERTY_IMAGE_RELATION_DELETED_V1: {
					const context = JSON.parse(event.context);
					const transformedContext = plainToInstance(PropertyImageRelationDeletedV1ContextDto, context);

					setEventContent(
						<div className='flex items-center gap-4'>
							<img src={transformedContext.image} alt='' width={50} height={50} className='rounded-xl' />

							<div className='text-[#C40E4D]'>{event.message}</div>
						</div>,
					);

					break;
				}

				default:
					setEventContent(<div>Unknown event type</div>);
			}
		};

		renderEventContent();
	}, [event, propertyCharacteristicImportUpdatedV1ShowMore, propertyCharacteristicUpdatedV1ShowMore]);

	return (
		<div className='flex flex-col gap-2 w-full p-4 bg-white border-solid border border-[#E4E4E7] rounded-xl'>
			{eventContent}

			{event.userFirstname && (
				<div className='flex justify-end italic text-[#71717A] text-sm'>
					par {event.userFirstname} {event.userLastname}
				</div>
			)}
		</div>
	);
};

export default RenderEvent;
