import { useCallback, useContext } from 'react';
import LanguageContext from '../Context/LanguageContext';

interface UseNumberFormat {
	format: (input: string | number) => string;
}

const useNumberFormat = (): UseNumberFormat => {
	const context = useContext(LanguageContext);

	if (!context) {
		throw new Error('useTranslate must be used within a LanguageContextProvider');
	}

	const { language } = context;

	const format = useCallback((input: string | number): string | null => {
		if (!input) return null;

		const number = parseFloat(input.toString());

		if (isNaN(number)) {
			return null;
		}

		return new Intl.NumberFormat(language.code.substring(0, 2), { maximumFractionDigits: 2 }).format(number);
	}, []);

	return { format };
};

export default useNumberFormat;
