import { Button, Drawer } from 'antd';
import { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Icon from '../Component/Icon/Icon';
import Navbar from '../Component/Navbar/Navbar';
import ArticleCreate from '../Page/Article/ArticleCreate/ArticleCreate';
import ArticleEdit from '../Page/Article/ArticleEdit/ArticleEdit';
import ArticleManage from '../Page/Article/ArticleManage/ArticleManage';
import CharacteristicCreate from '../Page/Characteristic/CharacteristicCreate/CharacteristicCreate';
import CharacteristicEdit from '../Page/Characteristic/CharacteristicEdit/CharacteristicEdit';
import CharacteristicWrapper from '../Page/Characteristic/CharacteristicWrapper';
import GroupCharacteristicCreate from '../Page/Characteristic/GroupCharacteristicCreate/GroupCharacteristicCreate';
import GroupCharacteristicEdit from '../Page/Characteristic/GroupCharacteristicEdit/GroupCharacteristicEdit';
import PermissionTeam from '../Page/Permission/PermissionTeam/PermissionTeam';
import PermissionUser from '../Page/Permission/PermissionUser/PermissionUser';
import PropertyEdit from '../Page/Property/PropertyEdit/PropertyEdit';
import PropertyManage from '../Page/Property/PropertyManage/PropertyManage';
import PropertyView from '../Page/Property/PropertyView/PropertyView';
import PropertyListCreate from '../Page/PropertyList/PropertyListCreate/PropertyListCreate';
import PropertyListEdit from '../Page/PropertyList/PropertyListEdit/PropertyListEdit';
import PropertyListManage from '../Page/PropertyList/PropertyListManage/PropertyListManage';
import TeamCreate from '../Page/Team/TeamCreate/TeamCreate';
import TeamEdit from '../Page/Team/TeamEdit/TeamEdit';
import TeamManage from '../Page/Team/TeamManage/TeamManage';
import UserCreate from '../Page/User/UserCreate/UserCreate';
import UserEdit from '../Page/User/UserEdit/UserEdit';
import UserManage from '../Page/User/UserManage/UserManage';
import UserListCreate from '../Page/UserList/UserListCreate/UserListCreate';
import UserListEdit from '../Page/UserList/UserListEdit/UserListEdit';
import UserListManage from '../Page/UserList/UserListManage/UserListManage';

const RootLayout = (): JSX.Element => {
	const location = useLocation();
	const [mobileNavbarVisible, setMobileNavbarVisible] = useState(false);

	useEffect(() => {
		setMobileNavbarVisible(false);
	}, [location]);

	return (
		<div className='flex flex-col 1k:flex-row min-h-screen box-border p-[40px] gap-[40px]'>
			<div className='sticky hidden 1k:flex grow-0 shrink-0 top-[40px] w-[250px] h-[calc(100vh-80px)]'>
				<Navbar />
			</div>

			<div className='flex justify-between 1k:hidden w-full'>
				<div className='flex justify-center items-center text-2xl font-bold'>ImmERP</div>
				<div>
					<Button onClick={(): void => setMobileNavbarVisible(!mobileNavbarVisible)} type='primary'>
						<Icon icon='faBars' color='#FFFFFF' />
					</Button>
				</div>
			</div>

			<div className='flex w-full 1k:w-[calc(100%-250px-40px)] gap-[40px]'>
				<Routes>
					{/* <Route path='/' element={<Home />} /> */}

					<Route path='/property/manage' element={<PropertyManage />} />
					{/* TODO Feature flag create property */}
					{/* <Route path='/property/create' element={<PropertyCreate />} /> */}
					<Route path='/property/view/:uuid' element={<PropertyView />} />
					<Route path='/property/edit/:uuid' element={<PropertyEdit />} />

					<Route path='/property-list/manage' element={<PropertyListManage />} />
					<Route path='/property-list/create' element={<PropertyListCreate />} />
					<Route path='/property-list/edit/:uuid' element={<PropertyListEdit />} />

					<Route path='/user-list/manage' element={<UserListManage />} />
					<Route path='/user-list/create' element={<UserListCreate />} />
					<Route path='/user-list/edit/:uuid' element={<UserListEdit />} />

					<Route path='/article/manage' element={<ArticleManage />} />
					<Route path='/article/create' element={<ArticleCreate />} />
					<Route path='/article/edit/:uuid' element={<ArticleEdit />} />

					{/* <Route path='/admin' element={<Admin />} /> */}

					<Route path='/admin/characteristic/manage' element={<CharacteristicWrapper />} />
					<Route path='/admin/characteristic/create' element={<CharacteristicCreate />} />
					<Route path='/admin/characteristic/edit/:uuid' element={<CharacteristicEdit />} />

					<Route path='/admin/group-characteristic/create' element={<GroupCharacteristicCreate />} />
					<Route path='/admin/group-characteristic/edit/:uuid' element={<GroupCharacteristicEdit />} />

					<Route path='/admin/team/manage' element={<TeamManage />} />
					<Route path='/admin/team/create' element={<TeamCreate />} />
					<Route path='/admin/team/edit/:uuid' element={<TeamEdit />} />

					<Route path='/admin/user/manage' element={<UserManage />} />
					<Route path='/admin/user/create' element={<UserCreate />} />
					<Route path='/admin/user/edit/:uuid' element={<UserEdit />} />

					<Route path='/admin/permission/teams' element={<PermissionTeam />} />
					<Route path='/admin/permission/users' element={<PermissionUser />} />
				</Routes>
			</div>

			<Drawer
				height='100%'
				placement='top'
				onClose={(): void => setMobileNavbarVisible(!mobileNavbarVisible)}
				open={mobileNavbarVisible}
			>
				<Navbar />
			</Drawer>
		</div>
	);
};

export default RootLayout;
