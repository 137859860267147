import { Button, DatePicker, Divider, Form, Input, InputNumber, Select, Spin, message, notification } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GetPropertyByUuidResponseDto } from '../../../../Dto/Response/GetPropertyByUuidResponseDto';
import { GetPropertyStatusResponseDto } from '../../../../Dto/Response/GetPropertyStatusResponseDto';
import { GetPropertyTypesResponseDto } from '../../../../Dto/Response/GetPropertyTypesResponseDto';
import useTranslate from '../../../../Hook/useTranslate';
import * as apiService from '../../../../Service/ApiService';

const { Option } = Select;

const PropertyGeneral = (): JSX.Element => {
	const { uuid } = useParams();
	const { translate } = useTranslate();
	const [notificationApi, notificationApiContextHolder] = notification.useNotification();

	const [propertyEditForm] = Form.useForm();

	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [isFetchingProperty, setIsFetchingProperty] = useState<boolean>(false);
	const [propertyTypes, setPropertyTypes] = useState<{ value: string; name: string }[]>([]);
	const [propertyStatus, setPropertyStatus] = useState<{ value: number; name: string }[]>([]);

	const [externalReference, setExternalReference] = useState<string | null>(null);

	useEffect(() => {
		const getPropertyByUuid = async (): Promise<void> => {
			setIsFetchingProperty(true);

			try {
				const result = await apiService.makeRequest<GetPropertyByUuidResponseDto>({
					route: `property/${uuid}`,
					method: 'GET',
				});

				propertyEditForm.setFieldsValue({
					name: result.name,
					description: result.description,
					forceUpdateFromImport: result.forceUpdateFromImport,
					visibleOnWeb: result.visibleOnWeb,
					type: result.type,
					nbRooms: result.nbRooms,
					saleDate: result.saleDate ? dayjs(result.saleDate) : null,
					fullPrice: result.fullPrice,
					embedVideoLink: result.embedVideoLink,
					status: result.status,
					favourite: result.favourite,
					addressLine1: result.address.line1,
					addressCity: result.address.city,
					addressPostalCode: result.address.postalCode,
					addressCountry: result.address.country,
					addressAdditionalInfo: result.address.additionalInfo,
				});

				setExternalReference(result.externalReference);
			} catch (error) {
				notificationApi.error({
					message: translate('error'),
					description: `${error}`,
				});

				console.error(error);
			} finally {
				setIsFetchingProperty(false);
			}
		};

		const getPropertyTypes = async (): Promise<void> => {
			try {
				const result = await apiService.makeRequest<GetPropertyTypesResponseDto>({
					route: 'property/types',
					method: 'GET',
				});

				setPropertyTypes(result.types);
			} catch (error) {
				notificationApi.error({
					message: translate('error'),
					description: `${error}`,
				});

				console.error(error);
			}
		};

		const getPropertyStatus = async (): Promise<void> => {
			try {
				const result = await apiService.makeRequest<GetPropertyStatusResponseDto>({
					route: 'property/status',
					method: 'GET',
				});

				setPropertyStatus(result.status);
			} catch (error) {
				notificationApi.error({
					message: translate('error'),
					description: `${error}`,
				});

				console.error(error);
			}
		};

		getPropertyByUuid();
		getPropertyTypes();
		getPropertyStatus();
	}, [propertyEditForm, uuid]);

	const submit = async (values: any): Promise<void> => {
		setIsSubmitting(true);

		try {
			await apiService.makeRequest({
				route: `property/update/${uuid}`,
				method: 'POST',
				data: {
					name: values.name,
					description: values.description,
					forceUpdateFromImport: values.forceUpdateFromImport ?? 0,
					visibleOnWeb: values.visibleOnWeb,
					type: values.type,
					fullPrice: values.fullPrice,
					nbRooms: values.nbRooms,
					saleDate: values.saleDate ? dayjs(values.saleDate).format('YYYY-MM-DD') : null,
					embedVideoLink: values.embedVideoLink,
					status: values.status,
					favourite: values.favourite,
					addressLine1: values.addressLine1,
					addressCity: values.addressCity,
					addressPostalCode: values.addressPostalCode,
					addressCountry: values.addressCountry,
					addressAdditionalInfo: values.addressAdditionalInfo,
				},
			});

			message.success(translate('property.edit.success'));
		} catch (error) {
			notificationApi.error({
				message: translate('error'),
				description: `${error}`,
			});

			console.error(error);
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<React.Fragment>
			{notificationApiContextHolder}

			<Spin spinning={isSubmitting} fullscreen />

			<Spin spinning={isFetchingProperty}>
				<Form form={propertyEditForm} onFinish={submit} layout='vertical' className='flex flex-col justify-between h-full'>
					<div className='flex flex-col gap-[20px]'>
						<div className='flex flex-col 650:flex-row gap-[20px]'>
							<Form.Item name='name' label={translate('property.edit.name')} required className='w-full 650:w-1/2'>
								<Input size='large' type='text' />
							</Form.Item>

							<Form.Item name='type' label={translate('property.edit.type')} required className='w-full 650:w-1/2'>
								<Select size='large'>
									{propertyTypes.map((type) => (
										<Option key={type.value} value={type.value}>
											{type.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						</div>

						<div className='flex flex-col 650:flex-row gap-[20px]'>
							<Form.Item name='fullPrice' label={translate('property.edit.fullPrice')} required className='w-full 650:w-1/3'>
								<InputNumber size='large' addonAfter='€' className='w-full' />
							</Form.Item>

							<Form.Item name='nbRooms' label={translate('property.edit.nbRooms')} required className='w-full 650:w-1/3'>
								<InputNumber size='large' className='w-full' />
							</Form.Item>

							<Form.Item name='saleDate' label={translate('property.edit.saleDate')} className='w-full 650:w-1/3'>
								<DatePicker
									size='large'
									placeholder={null}
									format={(currentDate): string => dayjs(currentDate).format('L')}
									className='w-full'
								/>
							</Form.Item>
						</div>

						<div className='flex flex-col 650:flex-row gap-[20px]'>
							<Form.Item
								name='embedVideoLink'
								label={translate('property.edit.embed_video_link')}
								className='w-full 650:w-1/2'
							>
								<Input size='large' type='text' />
							</Form.Item>

							<Form.Item name='status' label={translate('property.edit.status')} required className='w-full 650:w-1/2'>
								<Select size='large'>
									{propertyStatus.map((status) => (
										<Option key={status.value} value={status.value}>
											{status.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						</div>

						<Form.Item name='description' label={translate('property.edit.description')} className='w-full'>
							<Input.TextArea rows={6} />
						</Form.Item>

						<div className='flex flex-col 650:flex-row gap-[20px]'>
							{externalReference && (
								<Form.Item
									name='forceUpdateFromImport'
									label={translate('property.edit.force_update')}
									required
									className='w-full 650:w-1/3'
								>
									<Select size='large'>
										<Option value={1}>{translate('property.edit.force_update.yes')}</Option>
										<Option value={0}>{translate('property.edit.force_update.no')}</Option>
									</Select>
								</Form.Item>
							)}

							<Form.Item
								name='visibleOnWeb'
								label={translate('property.edit.visible_on_web')}
								required
								className={`w-full ${externalReference ? '650:w-1/3}' : '650:w-1/2'}`}
							>
								<Select size='large'>
									<Option value={1}>{translate('property.edit.visible_on_web.yes')}</Option>
									<Option value={0}>{translate('property.edit.visible_on_web.no')}</Option>
								</Select>
							</Form.Item>

							<Form.Item
								name='favourite'
								label={translate('property.edit.favourite')}
								required
								className={`w-full ${externalReference ? '650:w-1/3}' : '650:w-1/2'}`}
							>
								<Select size='large'>
									<Option value={1}>{translate('property.edit.favourite.yes')}</Option>
									<Option value={0}>{translate('property.edit.favourite.no')}</Option>
								</Select>
							</Form.Item>
						</div>

						<Divider orientation='left'>
							<span className='text-lg font-medium'>{translate('property.edit.address')}</span>
						</Divider>

						<Form.Item name='addressLine1' label={translate('property.edit.address_line_1')} className='w-full'>
							<Input size='large' type='text' />
						</Form.Item>

						<div className='flex flex-col 650:flex-row gap-[20px]'>
							<Form.Item name='addressCity' label={translate('property.edit.address_city')} className='w-full 650:w-1/3'>
								<Input size='large' type='text' />
							</Form.Item>

							<Form.Item
								name='addressPostalCode'
								label={translate('property.edit.address_postal_code')}
								className='w-full 650:w-1/3'
							>
								<Input size='large' type='text' />
							</Form.Item>

							<Form.Item
								name='addressCountry'
								label={translate('property.edit.address_country')}
								className='w-full 650:w-1/3'
							>
								<Input size='large' type='text' />
							</Form.Item>
						</div>

						<Form.Item
							name='addressAdditionalInfo'
							label={translate('property.edit.address_additional_info')}
							className='w-full'
						>
							<Input.TextArea rows={3} />
						</Form.Item>
					</div>

					<Form.Item className='flex justify-end mt-10'>
						<Button disabled={isSubmitting} size='large' block type='primary' htmlType='submit'>
							{translate('property.edit.save')}
						</Button>
					</Form.Item>
				</Form>
			</Spin>
		</React.Fragment>
	);
};

export default PropertyGeneral;
