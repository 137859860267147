import { Button, Divider, Form, Input, Select, Spin, message, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Head } from '../../../Component/Head/Head';
import Icon from '../../../Component/Icon/Icon';
import { Main } from '../../../Component/Main/Main';
import { GetCharacteristicByUuidResponseDto } from '../../../Dto/Response/GetCharacteristicByUuidResponseDto';
import {
	GetGroupsCharacteristicBasicListResponseDto,
	GroupsCharacteristicBasicListDto,
} from '../../../Dto/Response/GetGroupsCharacteristicBasicListResponseDto';
import useTranslate from '../../../Hook/useTranslate';
import * as apiService from '../../../Service/ApiService';

const { Option } = Select;

const CharacteristicEdit = (): JSX.Element => {
	const { uuid } = useParams();
	const { translate } = useTranslate();
	const navigate = useNavigate();
	const [characteristicEditForm] = Form.useForm();
	const [notificationApi, notificationApiContextHolder] = notification.useNotification();

	const [isFetchingGroupsCharacteristicList, setIsFetchingGroupsCharacteristicList] = useState<boolean>(false);
	const [isFetchingCharacteristic, setIsFetchingCharacteristic] = useState<boolean>(false);
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

	const [characteristicType, setCharacteristicType] = useState<string>('alphanumeric');
	const [valueType, setValueType] = useState<string>('string');
	const [groupsCharacteristic, setGroupsCharacteristic] = useState<GroupsCharacteristicBasicListDto[]>([]);

	useEffect(() => {
		const getGroupsCharacteristicBasicList = async (): Promise<void> => {
			setIsFetchingGroupsCharacteristicList(true);

			try {
				const result = await apiService.makeRequest<GetGroupsCharacteristicBasicListResponseDto>({
					route: 'group-characteristic/basic-list',
					method: 'GET',
				});

				setGroupsCharacteristic(result.groupsCharacteristic);
			} catch (error) {
				notificationApi.error({
					message: translate('error'),
					description: `${error}`,
				});

				console.error(error);
			} finally {
				setIsFetchingGroupsCharacteristicList(false);
			}
		};

		const getCharacteristicByUuid = async (): Promise<void> => {
			setIsFetchingCharacteristic(true);

			try {
				const result = await apiService.makeRequest<GetCharacteristicByUuidResponseDto>({
					route: `characteristic/${uuid}`,
					method: 'GET',
				});

				setCharacteristicType(result.characteristicType);

				characteristicEditForm.setFieldsValue({
					name: result.characteristic.name,
					characteristicType: result.characteristicType,
					groupCharacteristicUuid: result.characteristic.groupCharacteristicUuid,
				});

				if (result.characteristicType === 'specific') {
					characteristicEditForm.setFieldValue('specificValues', result.characteristicValues);
				} else if (result.characteristicType === 'alphanumeric') {
					characteristicEditForm.setFieldsValue({
						unit: result.characteristic.unit,
						valueType: result.characteristic.valueType,
					});
				}
			} catch (error) {
				notificationApi.error({
					message: translate('error'),
					description: `${error}`,
				});

				console.error(error);
			} finally {
				setIsFetchingCharacteristic(false);
			}
		};

		getGroupsCharacteristicBasicList();
		getCharacteristicByUuid();
	}, [uuid, characteristicEditForm]);

	const submit = async (values: any): Promise<void> => {
		setIsSubmitting(true);

		try {
			await apiService.makeRequest({
				route: `characteristic/update/${uuid}`,
				method: 'POST',
				data: {
					...values,
				},
			});

			setIsSubmitting(false);

			message.success(translate('characteristic.edit.success'));

			navigate('/admin/characteristic/manage');
		} catch (error) {
			notificationApi.error({
				message: translate('error'),
				description: `${error}`,
			});

			console.error(error);
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<React.Fragment>
			{notificationApiContextHolder}

			<Spin spinning={isSubmitting} fullscreen />

			<Main>
				<Head title={translate('characteristic.edit.title')} subtitle={translate('characteristic.edit.subtitle')} />

				<Form
					form={characteristicEditForm}
					onFinish={submit}
					initialValues={{ characteristicType: characteristicType, valueType: valueType }}
					layout='vertical'
					className='flex flex-col justify-between h-full'
				>
					<Spin spinning={isFetchingGroupsCharacteristicList || isFetchingCharacteristic}>
						<div className='flex flex-col gap-[20px]'>
							<div className='flex flex-col 650:flex-row gap-[20px]'>
								<Form.Item name='name' label={translate('characteristic.edit.name')} required className='w-full 650:w-1/2'>
									<Input size='large' type='text' />
								</Form.Item>

								<Form.Item
									name='characteristicType'
									label={translate('characteristic.edit.type')}
									required
									className='w-full 650:w-1/2'
								>
									<Select size='large' disabled>
										<Option value='alphanumeric'>{translate('characteristic.type.alphanumeric')}</Option>
										<Option value='specific'>{translate('characteristic.type.specific')}</Option>
									</Select>
								</Form.Item>
							</div>

							<Divider orientation='left'>
								<span className='text-lg font-medium'>{translate('characteristic.edit.section.parameters')}</span>
							</Divider>

							{characteristicType === 'alphanumeric' && (
								<div className='flex flex-col 650:flex-row gap-[20px]'>
									<Form.Item name='unit' label={translate('characteristic.edit.unit')} className='w-full 650:w-1/2'>
										<Input size='large' type='text' />
									</Form.Item>

									<Form.Item
										name='valueType'
										label={translate('characteristic.create.unit.type')}
										className='w-full 650:w-1/2'
									>
										<Select size='large' disabled>
											<Option value='string'>{translate('characteristic.unit.type.string')}</Option>
											<Option value='integer'>{translate('characteristic.unit.type.integer')}</Option>
											<Option value='float'>{translate('characteristic.unit.type.float')}</Option>
										</Select>
									</Form.Item>
								</div>
							)}

							{characteristicType === 'specific' && (
								<Form.List name='specificValues'>
									{(fields, { add, remove }): JSX.Element => (
										<React.Fragment>
											{fields.map(({ key, name }) => (
												<div key={`characteristic-value-${key}`} className='flex items-center gap-[20px]'>
													<Form.Item name={[name, 'value']} className='w-full'>
														<Input />
													</Form.Item>

													<Button danger type='text' onClick={(): void => remove(name)}>
														<Icon icon='faTrash' color='#F31260' />
													</Button>
												</div>
											))}

											<Form.Item>
												<Button type='dashed' onClick={(): void => add()} block icon={<Icon icon='faPlus' />}>
													{translate('characteristic.edit.add_value')}
												</Button>
											</Form.Item>
										</React.Fragment>
									)}
								</Form.List>
							)}

							<Divider orientation='left'>
								<span className='text-lg font-medium'>{translate('characteristic.edit.section.group')}</span>
							</Divider>

							<Form.Item
								name='groupCharacteristicUuid'
								label={translate('characteristic.edit.group')}
								className='w-full 650:w-1/2'
							>
								<Select size='large' showSearch allowClear optionFilterProp='children'>
									{groupsCharacteristic.map((group) => (
										<Option key={group.uuid} value={group.uuid}>
											{group.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						</div>
					</Spin>

					<Form.Item className='flex justify-end mt-10'>
						<Button
							disabled={isSubmitting || isFetchingGroupsCharacteristicList || isFetchingCharacteristic}
							size='large'
							block
							type='primary'
							htmlType='submit'
						>
							{translate('characteristic.edit.save')}
						</Button>
					</Form.Item>
				</Form>
			</Main>
		</React.Fragment>
	);
};

export default CharacteristicEdit;
