import type { Active, UniqueIdentifier } from '@dnd-kit/core';
import { DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import type { ReactNode } from 'react';
import React, { useMemo, useState } from 'react';

import { DragHandle, SortableItem } from './SortableItem';
import { SortableOverlay } from './SortableOverlay';

interface BaseItem {
	uuid: UniqueIdentifier;
}

interface Props<T extends BaseItem> {
	items: T[];
	onChange(items: T[]): void;
	renderItem(item: T): ReactNode;
}

export const SortableList = <T extends BaseItem>({ items, onChange, renderItem }: Props<T>): JSX.Element => {
	const [active, setActive] = useState<Active | null>(null);

	const activeItem = useMemo(() => items.find((item) => item.uuid === active?.id), [active, items]);

	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		}),
	);

	return (
		<DndContext
			sensors={sensors}
			onDragStart={({ active }): void => {
				setActive(active);
			}}
			onDragEnd={({ active, over }): void => {
				if (over && active.id !== over?.id) {
					const activeIndex = items.findIndex(({ uuid }) => uuid === active.id);
					const overIndex = items.findIndex(({ uuid }) => uuid === over.id);

					onChange(arrayMove(items, activeIndex, overIndex));
				}
				setActive(null);
			}}
			onDragCancel={(): void => {
				setActive(null);
			}}
		>
			<SortableContext items={items.map((item) => item.uuid)}>
				<div className='flex flex-col gap-[10px] p-0'>
					{items.map((item) => (
						<React.Fragment key={item.uuid}>{renderItem(item)}</React.Fragment>
					))}
				</div>
			</SortableContext>

			<SortableOverlay>{activeItem ? renderItem(activeItem) : null}</SortableOverlay>
		</DndContext>
	);
};

SortableList.Item = SortableItem;
SortableList.DragHandle = DragHandle;
