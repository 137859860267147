import { Image } from 'antd';

interface ImageModalProps {
	isVisible: boolean;
	imageUrl: string;
	onClose: () => void;
}

const ImagePreview = ({ isVisible, imageUrl, onClose }: ImageModalProps): JSX.Element => (
	<Image
		src={null}
		height={0}
		width={0}
		preview={{
			visible: isVisible,
			src: imageUrl,
			toolbarRender: () => null,
			onVisibleChange: () => {
				onClose();
			},
		}}
	/>
);

export default ImagePreview;
