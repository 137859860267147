import { Button, Tag, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Main } from '../../../Component/Main/Main';
import RenderEvent from '../../../Component/RenderEvent/RenderEvent';
import { Timeline } from '../../../Component/Timeline/Timeline';
import useNumberFormat from '../../../Hook/useNumberFormat';
import useTranslate from '../../../Hook/useTranslate';
import * as apiService from '../../../Service/ApiService';

const PropertyView = (): JSX.Element => {
	const { uuid } = useParams();
	const { translate } = useTranslate();
	const { format } = useNumberFormat();
	const navigate = useNavigate();
	const [notificationApi, notificationApiContextHolder] = notification.useNotification();

	const [isFetchingPropertyInformations, setIsFetchingPropertyInformations] = useState<boolean>(false);

	const [propertyInformations, setPropertyInformations] = useState<any>(null);
	const [timelineItems, setTimelineItems] = useState<any>([]);

	useEffect(() => {
		const getPropertyInformations = async (): Promise<void> => {
			setIsFetchingPropertyInformations(true);

			try {
				const result: any = await apiService.makeRequest<any>({
					route: `property/${uuid}/informations`,
					method: 'GET',
				});

				setPropertyInformations(result);
				setTimelineItems(result.events);

				// console.log(result);
			} catch (error) {
				notificationApi.error({
					message: translate('error'),
					description: `${error}`,
				});

				console.error(error);
			} finally {
				setIsFetchingPropertyInformations(false);
			}
		};

		getPropertyInformations();
	}, [uuid]);

	const formatTimelineLabel = (createdAt: string): JSX.Element => {
		const dateObj = new Date(createdAt);
		const formattedTime = `${dateObj.getHours()}h${dateObj.getMinutes().toString().padStart(2, '0')}`;
		const formattedDate = `${dateObj.getDate().toString().padStart(2, '0')}/${(dateObj.getMonth() + 1)
			.toString()
			.padStart(2, '0')}/${dateObj.getFullYear()}`;

		return (
			<div className='flex justify-end'>
				<div className='flex flex-col items-end'>
					<div className='text-[#5078FD] font-semibold'>{formattedTime}</div>
					{formattedDate}
				</div>
			</div>
		);
	};

	return (
		<React.Fragment>
			<Main showBackground={false}>
				<div className='relative flex flex-col-reverse 1k450:flex-row min-h-full gap-10'>
					<div className='1k450:w-2/3 flex flex-col gap-10'>
						<div className='font-semibold text-xl'>Suivi de l'activité</div>

						<Timeline
							items={timelineItems.map((item: any) => ({
								date: formatTimelineLabel(item.createdAt),
								content: <RenderEvent event={item} />,
							}))}
						/>
					</div>

					<div className='1k450:sticky 1k450:top-[104px] flex flex-col gap-6 1k450:min-w-[500px] 1k450:w-1/3 1k450:max-h-[calc(100vh-208px)] overflow-y-auto bg-white shadow-immerp px-10 pt-10 box-border rounded-xl'>
						{propertyInformations?.images.length > 0 && (
							<div className='flex gap-4'>
								<div className='flex-1 min-h-64 overflow-hidden rounded-xl'>
									<div
										style={{ background: `url(${propertyInformations.images[0].thumbnailUrl})` }}
										className='w-full h-full !bg-cover !bg-center'
									></div>
								</div>

								{propertyInformations?.images.length > 2 && (
									<div className='flex flex-col gap-4'>
										<div className='w-32 aspect-square overflow-hidden  rounded-xl'>
											<div
												style={{ background: `url(${propertyInformations.images[1].thumbnailUrl})` }}
												className='w-full h-full !bg-cover !bg-center'
											></div>
										</div>
										<div className='w-32 aspect-square overflow-hidden  rounded-xl'>
											<div
												style={{ background: `url(${propertyInformations.images[2].thumbnailUrl})` }}
												className='w-full h-full !bg-cover !bg-center'
											></div>
										</div>
									</div>
								)}
							</div>
						)}

						<div className='flex justify-between items-center'>
							<div className='font-semibold'>{propertyInformations?.name}</div>

							<Tag bordered={false} color='processing'>
								{propertyInformations?.type}
							</Tag>
						</div>

						<div className='w-full min-h-[2px] bg-[#D4D4D8]'></div>

						<div className='flex-1 flex justify-between'>
							<div className='flex flex-col w-1/3 gap-4'>
								<div className='text-[#11181C] text-opacity-50'>Prix</div>
								<div className='text-[#11181C] text-opacity-50'>Statut du bien</div>
								<div className='text-[#11181C] text-opacity-50'>Adresse</div>
							</div>
							<div className='flex flex-col items-end gap-4 w-2/3 text-right'>
								<div className='font-semibold'>{format(propertyInformations?.fullPrice)} €</div>
								{((): JSX.Element => {
									let statusColor = 'processing';

									switch (`${propertyInformations?.status}`) {
										case '1':
										case '3':
										case '4':
											statusColor = 'orange';
											break;
										case '2':
											statusColor = 'processing';
											break;
										case '5':
										case '6':
											statusColor = 'success';
											break;
										default:
											statusColor = 'processing';
											break;
									}

									return (
										<Tag bordered={false} color={statusColor}>
											{propertyInformations?.statusName}
										</Tag>
									);
								})()}
								<div className='font-semibold'>
									{propertyInformations?.address.city}, {propertyInformations?.address.postalCode}{' '}
									{propertyInformations?.address.country}
								</div>
							</div>
						</div>

						<div className='sticky bottom-0 left-0 flex w-full justify-end py-6 box-border bg-white'>
							<Button
								className='w-1/2'
								type='primary'
								size='large'
								onClick={(): void => navigate(`/property/edit/${propertyInformations?.uuid}`)}
							>
								Editer le bien
							</Button>
						</div>
					</div>
				</div>
			</Main>
		</React.Fragment>
	);
};

export default PropertyView;
