import {
	Button,
	DatePicker,
	Divider,
	Form,
	Input,
	Modal,
	Popconfirm,
	Select,
	Spin,
	Upload,
	UploadProps,
	message,
	notification,
} from 'antd';
import { GetProp } from 'antd/lib';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import slugify from 'slugify';
import { Head } from '../../../Component/Head/Head';
import Icon from '../../../Component/Icon/Icon';
import { Main } from '../../../Component/Main/Main';
import { UploadImageResponseDto } from '../../../Dto/Response/UploadImageResponseDto';
import useTranslate from '../../../Hook/useTranslate';
import * as apiService from '../../../Service/ApiService';
import { CreateItem } from '../_Component/CreateItem';
import { EditItem } from '../_Component/EditItem';
import { SortableList } from '../_Component/SortableList';

const { Option } = Select;

type itemType = {
	id: string;
	type: 'paragraph' | 'image';
	content: itemContent;
};

type itemContent = {
	text?: string;
	imageUuid?: string;
	imageExtension?: string;
	imageCaption?: string;
	imageUrl?: string;
};

const ArticleCreate = (): JSX.Element => {
	const { translate } = useTranslate();
	const navigate = useNavigate();
	const [articleCreateForm] = Form.useForm();
	const [notificationApi, notificationApiContextHolder] = notification.useNotification();

	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [isUploading, setIsUploading] = useState<boolean>(false);

	const [isModalCreateVisible, setIsModalCreateVisible] = useState(false);
	const [isModalEditVisible, setIsModalEditVisible] = useState(false);
	const [selectedItem, setSelectedItem] = useState<itemType | null>(null);
	const [items, setItems] = useState<itemType[]>([]);

	const [imageUrl, setImageUrl] = useState<string | null>(null);
	const [imageUuid, setImageUuid] = useState<string | null>(null);

	const updateSlug = (value: string): void => {
		articleCreateForm.setFieldsValue({
			slug: slugify(value, { lower: true }),
		});
	};

	const addItem = (type: 'paragraph' | 'image', content: itemContent): void => {
		const newItem: itemType = {
			id: (Math.random() + 1).toString(36).substring(7),
			type: type,
			content: content,
		};
		setItems((prevItems) => [...prevItems, newItem]);
		setIsModalCreateVisible(!isModalCreateVisible);
	};

	const handleModalEdit = (item: any): void => {
		setSelectedItem(item);
		setIsModalEditVisible(!isModalEditVisible);
	};

	const updateSelectedItem = (updatedItem: itemType): void => {
		const updatedItems = items.map((item) => (item.id === updatedItem.id ? updatedItem : item));
		setItems(updatedItems);
		setIsModalEditVisible(!isModalEditVisible);
	};

	const submit = async (values: any): Promise<void> => {
		setIsSubmitting(true);

		try {
			await apiService.makeRequest({
				route: 'article/create',
				method: 'POST',
				data: {
					name: values.name,
					slug: values.slug,
					publishedDate: dayjs(values.publishedDate).format('YYYY-MM-DD'),
					status: values.status,
					description: values.description,
					imageUuid: imageUuid,
					items: items.map((item) => ({
						type: item.type,
						...item.content,
					})),
				},
			});

			setIsSubmitting(false);

			message.success(translate('article.create.success'));

			navigate('/article/manage');
		} catch (error) {
			notificationApi.error({
				message: translate('error'),
				description: `${error}`,
			});

			console.error(error);
		} finally {
			setIsSubmitting(false);
		}
	};

	type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

	const beforeUpload = (file: FileType): boolean => {
		const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

		if (!isJpgOrPng) {
			message.error(translate('user.create.image_restriction'));
			return;
		}

		return isJpgOrPng;
	};

	const handleFileChange = (info: any): void => {
		const file = info.file.originFileObj as FileType;

		const reader = new FileReader();

		reader.addEventListener('load', async () => {
			await uploadImage(reader.result as string);
			setImageUrl(reader.result as string);
		});

		reader.readAsDataURL(file);
	};

	const uploadImage = async (base64: string): Promise<void> => {
		setIsUploading(true);

		try {
			const result = await apiService.makeRequest<UploadImageResponseDto>({
				route: 'image/upload',
				method: 'POST',
				data: {
					base64,
				},
			});

			setImageUuid(result.image.uuid);
		} catch (error) {
			notificationApi.error({
				message: translate('error'),
				description: `${error}`,
			});

			console.error(error);
		} finally {
			setIsUploading(false);
		}
	};

	return (
		<React.Fragment>
			{notificationApiContextHolder}

			<Spin spinning={isSubmitting} fullscreen />

			<Main>
				<Head title={translate('article.create.title')} subtitle={translate('article.create.subtitle')} />

				<Form
					form={articleCreateForm}
					onFinish={submit}
					initialValues={{
						status: 'draft',
					}}
					layout='vertical'
					className='flex ${} flex-col justify-between h-full'
				>
					<Spin spinning={isUploading}>
						<div className='flex flex-col'>
							<div className='flex flex-col gap-[20px]'>
								<div className='flex flex-col 650:flex-row gap-[20px]'>
									<Form.Item name='name' label={translate('article.create.name')} required className='w-full 650:w-1/2'>
										<Input
											size='large'
											type='text'
											autoComplete='off'
											onChange={(e: React.ChangeEvent<HTMLInputElement>): void => updateSlug(e.target.value)}
										/>
									</Form.Item>

									<Form.Item name='slug' label={translate('article.create.slug')} required className='w-full 650:w-1/2'>
										<Input size='large' type='text' autoComplete='off' />
									</Form.Item>
								</div>

								<div className='flex flex-col 650:flex-row gap-[20px]'>
									<Form.Item
										name='publishedDate'
										label={translate('article.create.published_date')}
										required
										className='w-full 650:w-1/2'
									>
										<DatePicker
											size='large'
											placeholder={null}
											format={(currentDate): string => dayjs(currentDate).format('L')}
											className='w-full'
										/>
									</Form.Item>

									<Form.Item
										name='status'
										label={translate('article.create.status')}
										required
										className='w-full 650:w-1/2'
									>
										<Select size='large'>
											<Option value='draft'>{translate('article.status.draft')}</Option>
											<Option value='published'>{translate('article.status.published')}</Option>
										</Select>
									</Form.Item>
								</div>

								<div className='flex justify-between gap-[20px]'>
									<Form.Item
										name='description'
										label={translate('article.create.description')}
										className='w-full 650:w-4/5'
									>
										<Input.TextArea rows={4} />
									</Form.Item>

									<Form.Item label={translate('article.create.image')} className='flex justify-end w-1/5'>
										<Upload
											listType='picture-card'
											showUploadList={false}
											customRequest={(): void => undefined}
											beforeUpload={beforeUpload}
											onChange={handleFileChange}
										>
											{imageUrl ? (
												<img src={imageUrl} alt='avatar' className='w-full' />
											) : (
												<button type='button' className='bg-transparent border-none'>
													<div>{translate('user.create.image')}</div>
												</button>
											)}
										</Upload>
									</Form.Item>
								</div>
							</div>

							<Divider orientation='left'>
								<span className='text-lg font-medium'>{translate('article.create.content')}</span>
							</Divider>

							<div className='flex flex-col gap-[10px]'>
								<Button
									type='dashed'
									onClick={(): void => setIsModalCreateVisible(!isModalCreateVisible)}
									block
									icon={<Icon icon='faPlus' />}
								>
									{translate('article.create.add_item')}
								</Button>

								<SortableList
									items={items}
									onChange={setItems}
									renderItem={(item): JSX.Element => (
										<SortableList.Item id={item.id}>
											<div className='flex items-center'>
												<div className='flex h-8'>
													<SortableList.DragHandle />
												</div>

												{item.type === 'paragraph' && (
													<div
														className='line-clamp-3 px-2 [&>p]:m-0'
														dangerouslySetInnerHTML={{ __html: item.content.text }}
													></div>
												)}

												{item.type === 'image' && (
													<div className='flex items-center px-2 gap-4'>
														<img src={item.content.imageUrl} alt={item.content.imageCaption} className='w-16' />
														<div className='italic break-all'>{item.content.imageCaption}</div>
													</div>
												)}
											</div>

											<div className='flex justify-end items-center gap-3 h-8'>
												<div
													onClick={(): void => handleModalEdit(item)}
													className='flex items-center justify-center px-[10px] h-full rounded-lg cursor-pointer hover:bg-[rgba(0,_0,_0,_0.05)] transition-all'
												>
													<Icon icon='faPencil' color='#5078FD' />
												</div>

												<div className='flex items-center justify-center h-full rounded-lg cursor-pointer hover:bg-[rgba(0,_0,_0,_0.05)] transition-all'>
													<Popconfirm
														placement='topRight'
														icon={
															<div className='mr-2'>
																<Icon icon='faWarning' color='#F31260' size={15} />
															</div>
														}
														title={translate('article.create.delete_item')}
														okText={translate('article.create.confirm_delete_item')}
														okButtonProps={{
															danger: true,
														}}
														cancelText={translate('article.create.cancel_delete_item')}
														onConfirm={(): void =>
															setItems((prevItems) => prevItems.filter((prevItem) => prevItem.id !== item.id))
														}
													>
														<Button ghost className='border-none px-[10px] h-full'>
															<Icon icon='faTrash' color='#F31260' />
														</Button>
													</Popconfirm>
												</div>
											</div>
										</SortableList.Item>
									)}
								/>
							</div>
						</div>
					</Spin>

					<Form.Item className='flex justify-end mt-10'>
						<Button disabled={isSubmitting || isUploading} size='large' block type='primary' htmlType='submit'>
							{translate('article.create.save')}
						</Button>
					</Form.Item>
				</Form>
			</Main>

			<Modal
				key={`article_modal_create_${Date.now()}`}
				open={isModalCreateVisible}
				width={1000}
				footer={null}
				onCancel={(): void => setIsModalCreateVisible(!isModalCreateVisible)}
			>
				<CreateItem addItem={addItem} />
			</Modal>

			<Modal
				key={`article_modal_edit_${Date.now()}`}
				open={isModalEditVisible}
				width={1000}
				footer={null}
				onCancel={(): void => handleModalEdit(null)}
			>
				<EditItem item={selectedItem} updateItem={updateSelectedItem} />
			</Modal>
		</React.Fragment>
	);
};

export default ArticleCreate;
