import { Button, Divider, Form, Input, Popconfirm, Select, Spin, message, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import slugify from 'slugify';
import { Head } from '../../../Component/Head/Head';
import Icon from '../../../Component/Icon/Icon';
import { Main } from '../../../Component/Main/Main';
import { GetPropertyListByUuidResponseDto } from '../../../Dto/Response/GetPropertyListByUuidResponseDto';
import useDebounce from '../../../Hook/useDebounce';
import useNumberFormat from '../../../Hook/useNumberFormat';
import useTranslate from '../../../Hook/useTranslate';
import * as apiService from '../../../Service/ApiService';
import { SortableList } from '../_Component/SortableList';
import { PropertyType } from '../_Type/PropertyType';

const { Option } = Select;

const PropertyListEdit = (): JSX.Element => {
	const { uuid } = useParams();
	const { translate } = useTranslate();
	const { format } = useNumberFormat();

	const navigate = useNavigate();
	const [propertyListEditForm] = Form.useForm();
	const [notificationApi, notificationApiContextHolder] = notification.useNotification();

	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [isFetchingPropertyList, setIsFetchingPropertyList] = useState<boolean>(false);
	const [isFetchingProperties, setIsFetchingProperties] = useState<boolean>(false);

	const [query, setQuery] = useState<string>('');
	const debounceQuery = useDebounce<string>(query, 500);

	const [properties, setProperties] = useState<PropertyType[]>([]);
	const [selectedProperties, setSelectedProperties] = useState<PropertyType[]>([]);

	useEffect(() => {
		const getPropertyListByUuid = async (): Promise<void> => {
			setIsFetchingPropertyList(true);

			try {
				const result = await apiService.makeRequest<GetPropertyListByUuidResponseDto>({
					route: `property-list/${uuid}`,
					method: 'GET',
				});

				propertyListEditForm.setFieldsValue({
					name: result.name,
					slug: result.slug,
				});

				setSelectedProperties(result.properties);
			} catch (error) {
				notificationApi.error({
					message: translate('error'),
					description: `${error}`,
				});

				console.error(error);
			} finally {
				setIsFetchingPropertyList(false);
			}
		};

		getPropertyListByUuid();
	}, [uuid, propertyListEditForm]);

	const updateSlug = (value: string): void => {
		propertyListEditForm.setFieldsValue({
			slug: slugify(value, { lower: true }),
		});
	};

	const getPropertiesList = async (): Promise<void> => {
		setIsFetchingProperties(true);

		try {
			const result = await apiService.makeRequest<any>({
				route: 'property/list',
				method: 'POST',
				data: {
					query: debounceQuery,
					currentPage: 1,
					pageSize: 20,
				},
			});

			setProperties(result.properties);
		} catch (error) {
			notificationApi.error({
				message: translate('error'),
				description: `${error}`,
			});

			console.error(error);
		} finally {
			setIsFetchingProperties(false);
		}
	};

	useEffect(() => {
		getPropertiesList();
	}, [debounceQuery]);

	const handleSearchPropertySelect = (value: string): void => {
		const property = properties.find((property: PropertyType) => property.uuid === value);

		setSelectedProperties((prevSelected: PropertyType[]) => {
			const alreadySelected = prevSelected.some((selectedProperty) => selectedProperty.uuid === value);
			if (!alreadySelected) {
				return [...prevSelected, property];
			}
			return prevSelected;
		});
	};

	const submit = async (values: any): Promise<void> => {
		setIsSubmitting(true);

		try {
			await apiService.makeRequest({
				route: `property-list/update/${uuid}`,
				method: 'POST',
				data: {
					name: values.name,
					slug: values.slug,
					properties: selectedProperties.map((selectedProperty) => ({
						uuid: selectedProperty.uuid,
					})),
				},
			});

			message.success(translate('property_list.edit.success'));

			navigate('/property-list/manage');
		} catch (error) {
			notificationApi.error({
				message: translate('error'),
				description: `${error}`,
			});

			console.error(error);
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<React.Fragment>
			{notificationApiContextHolder}

			<Spin spinning={isSubmitting} fullscreen />

			<Main>
				<Spin spinning={isFetchingPropertyList}>
					<Head title={translate('property_list.edit.title')} subtitle={translate('property_list.edit.subtitle')} />

					<Form
						form={propertyListEditForm}
						onFinish={submit}
						layout='vertical'
						className='flex ${} flex-col justify-between h-full'
					>
						<div className='flex flex-col'>
							<div className='flex flex-col gap-[20px]'>
								<div className='flex flex-col 650:flex-row gap-[20px]'>
									<Form.Item
										name='name'
										label={translate('property_list.edit.name')}
										required
										className='w-full 650:w-1/2'
									>
										<Input
											size='large'
											type='text'
											autoComplete='off'
											onChange={(e: React.ChangeEvent<HTMLInputElement>): void => updateSlug(e.target.value)}
										/>
									</Form.Item>

									<Form.Item
										name='slug'
										label={translate('property_list.edit.slug')}
										required
										className='w-full 650:w-1/2'
									>
										<Input size='large' type='text' autoComplete='off' />
									</Form.Item>
								</div>

								<Form.Item label={translate('property_list.edit.add_property')} className='w-full'>
									<Select
										showSearch
										searchValue={query}
										loading={isFetchingProperties}
										filterOption={false}
										onSearch={(event): void => setQuery(event)}
										onSelect={(event): void => handleSearchPropertySelect(event)}
										onBlur={(): void => setQuery(query)}
										value={null}
										size='large'
									>
										{properties.map((property: PropertyType) => (
											<Option key={property.uuid} value={property.uuid}>
												{property.name}{' '}
												{property.fullPrice !== null && <span>- {format(property.fullPrice)} €</span>}
											</Option>
										))}
									</Select>
								</Form.Item>
							</div>

							<Divider orientation='left'>
								<span className='text-lg font-medium'>{translate('property_list.edit.property')}</span>
							</Divider>

							<div className='flex flex-col gap-[10px]'>
								<SortableList
									items={selectedProperties}
									onChange={setSelectedProperties}
									renderItem={(item: PropertyType): JSX.Element => (
										<SortableList.Item uuid={item.uuid}>
											<div className='flex items-center h-full gap-4'>
												<div className='flex h-8'>
													<SortableList.DragHandle />
												</div>

												<div className='min-w-36 h-24'>
													<div
														className='w-full h-full flex justify-center items-center rounded-lg'
														style={{
															background: `url(${item.mainThumbnailUrl})`,
															backgroundRepeat: 'no-repeat',
															backgroundSize: 'cover',
															backgroundPosition: 'center',
														}}
													>
														{!item.mainThumbnailUrl && <Icon icon='faImage' size={40} color='#E4E4E7' />}
													</div>
												</div>

												<div className='flex flex-col gap-[2px]'>
													<Link to={`/property/edit/${item.uuid}`} target='_blank' rel='noopener noreferrer'>
														<div className='flex items-center gap-1'>
															<span>{item.name}</span>
															<Icon icon='faLink' size={12} color='#5078fd' />
														</div>
													</Link>

													<span>{item.city}</span>

													{item.fullPrice !== null && <span>{format(item.fullPrice)} €</span>}

													<div>
														{item.visibleOnWeb ? (
															<span className='inline-flex justify-center items-center gap-1 bg-[#E9F7F1] text-[#3AB795] text-xs px-2 py-1 rounded-lg'>
																<Icon icon='faEye' size={10} color='#3AB795' />
																<span>{translate('property_list.edit.visible_on_web.yes')}</span>
															</span>
														) : (
															<span className='inline-flex justify-center items-center gap-1 bg-[#FFE6EA] text-[#F31260] text-xs px-2 py-1 rounded-lg'>
																<Icon icon='faEyeSlash' size={10} color='#F31260' />
																<span>{translate('property_list.edit.visible_on_web.no')}</span>
															</span>
														)}
													</div>
												</div>
											</div>

											<div className='flex justify-end items-center gap-3 h-8'>
												<div className='flex items-center justify-center h-full rounded-lg cursor-pointer hover:bg-[rgba(0,_0,_0,_0.05)] transition-all'>
													<Popconfirm
														placement='topRight'
														icon={
															<div className='mr-2'>
																<Icon icon='faWarning' color='#F31260' size={15} />
															</div>
														}
														title={translate('property_list.edit.delete_item')}
														okText={translate('property_list.edit.confirm_delete_item')}
														okButtonProps={{
															danger: true,
														}}
														cancelText={translate('property_list.edit.cancel_delete_item')}
														onConfirm={(): void =>
															setSelectedProperties((prevSelected: PropertyType[]) =>
																prevSelected.filter((prevProperty) => prevProperty.uuid !== item.uuid),
															)
														}
													>
														<Button ghost className='border-none px-[10px] h-full'>
															<Icon icon='faTrash' color='#F31260' />
														</Button>
													</Popconfirm>
												</div>
											</div>
										</SortableList.Item>
									)}
								/>
							</div>
						</div>

						<Form.Item className='flex justify-end mt-10'>
							<Button disabled={isSubmitting} size='large' block type='primary' htmlType='submit'>
								{translate('property_list.edit.save')}
							</Button>
						</Form.Item>
					</Form>
				</Spin>
			</Main>
		</React.Fragment>
	);
};

export default PropertyListEdit;
