import { Button, Divider, Form, Input, Spin, message, notification } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Head } from '../../../Component/Head/Head';
import Icon from '../../../Component/Icon/Icon';
import { Main } from '../../../Component/Main/Main';
import useTranslate from '../../../Hook/useTranslate';
import * as apiService from '../../../Service/ApiService';

const TeamCreate = (): JSX.Element => {
	const { translate } = useTranslate();
	const navigate = useNavigate();
	const [teamCreateForm] = Form.useForm();

	const [notificationApi, notificationApiContextHolder] = notification.useNotification();

	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

	const submit = async (values: any): Promise<void> => {
		setIsSubmitting(true);

		try {
			await apiService.makeRequest({
				route: 'team/create',
				method: 'POST',
				data: {
					...values,
				},
			});

			message.success(translate('team.create.success'));

			navigate('/admin/team/manage');
		} catch (error) {
			notificationApi.error({
				message: translate('error'),
				description: `${error}`,
			});

			console.error(error);
		} finally {
			setIsSubmitting(false);
		}
	};

	const addRole = (): void => {
		teamCreateForm.setFieldsValue({
			roles: [...teamCreateForm.getFieldValue('roles'), { name: undefined, isRemovable: 1, isAdmin: 0 }],
		});
	};

	return (
		<React.Fragment>
			{notificationApiContextHolder}

			<Spin spinning={isSubmitting} fullscreen />

			<Main>
				<Head title={translate('team.create.title')} subtitle={translate('team.create.subtitle')} marginBottom='64px' />

				<Form
					form={teamCreateForm}
					onFinish={submit}
					initialValues={{
						roles: [
							{ name: translate('team.create.role.admin'), isRemovable: 0, isAdmin: 1 },
							{ name: translate('team.create.role.user'), isRemovable: 0, isAdmin: 0 },
						],
					}}
					layout='vertical'
					className='flex flex-col justify-between h-full'
				>
					<div className='flex flex-col gap-[20px]'>
						<div className='flex flex-col 650:flex-row gap-[20px]'>
							<Form.Item name='name' label={translate('team.create.name')} required className='w-full 1k:w-1/2'>
								<Input size='large' type='text' />
							</Form.Item>
						</div>

						<Divider orientation='left'>
							<span className='text-lg font-medium'>{translate('team.create.contact')}</span>
						</Divider>

						<div className='flex flex-col 650:flex-row gap-[20px]'>
							<Form.Item name='email' label={translate('team.create.mail')} className='w-full 650:w-1/2'>
								<Input suffix={<Icon icon='faEnvelope' color='#A1A1AA' />} size='large' type='text' autoComplete='off' />
							</Form.Item>

							<Form.Item name='phone' label={translate('team.create.phone')} className='w-full 650:w-1/2'>
								<Input suffix={<Icon icon='faPhone' color='#A1A1AA' />} size='large' type='text' autoComplete='off' />
							</Form.Item>
						</div>

						<div className='flex flex-col 650:flex-row gap-[20px]'>
							<Form.Item name='line1' label={translate('team.create.line1')} className='w-full 650:w-1/2'>
								<Input size='large' type='text' autoComplete='off' />
							</Form.Item>

							<Form.Item name='additionalInfo' label={translate('team.create.additionalInfo')} className='w-full 650:w-1/2'>
								<Input size='large' type='text' autoComplete='off' />
							</Form.Item>
						</div>

						<div className='flex flex-col 650:flex-row gap-[20px]'>
							<Form.Item name='city' label={translate('team.create.city')} className='w-full 650:w-1/2'>
								<Input size='large' type='text' autoComplete='off' />
							</Form.Item>

							<Form.Item name='country' label={translate('team.create.country')} className='w-full 650:w-1/2'>
								<Input size='large' type='text' autoComplete='off' />
							</Form.Item>

							<Form.Item name='postalCode' label={translate('team.create.postalCode')} className='w-full 650:w-1/2'>
								<Input size='large' type='text' autoComplete='off' />
							</Form.Item>
						</div>

						<Divider orientation='left'>
							<span className='text-lg font-medium'>{translate('team.create.roles')}</span>
						</Divider>

						<Form.List name='roles'>
							{(fields, { remove }): JSX.Element => (
								<React.Fragment>
									{fields.map(({ key, name }, index) => {
										const roles = teamCreateForm.getFieldValue('roles');
										const role = roles[key];

										return (
											<div key={`create-role-name-${key}`} className='flex items-center gap-[20px]'>
												<Form.Item name={[name, 'name']} className='w-full'>
													<Input disabled={role.isRemovable === 0} size='large' />
												</Form.Item>

												{role.isRemovable === 1 && (
													<Button danger type='text' onClick={(): void => remove(name)}>
														<Icon icon='faTrash' color='#F31260' />
													</Button>
												)}
											</div>
										);
									})}

									<Form.Item>
										<Button type='dashed' onClick={(): void => addRole()} block icon={<Icon icon='faPlus' />}>
											{translate('team.create.add_role')}
										</Button>
									</Form.Item>
								</React.Fragment>
							)}
						</Form.List>
					</div>

					<Form.Item className='flex justify-end mt-10'>
						<Button disabled={isSubmitting} size='large' block type='primary' htmlType='submit'>
							{translate('team.create.save')}
						</Button>
					</Form.Item>
				</Form>
			</Main>
		</React.Fragment>
	);
};

export default TeamCreate;
