import { Button, Divider, Form, Input, Select, Spin, Upload, UploadProps, message, notification } from 'antd';
import { GetProp } from 'antd/lib';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Head } from '../../../Component/Head/Head';
import Icon from '../../../Component/Icon/Icon';
import { Main } from '../../../Component/Main/Main';
import { GetTeamsListResponseDto, TeamListDto } from '../../../Dto/Response/GetTeamsListResponseDto';
import { UploadImageResponseDto } from '../../../Dto/Response/UploadImageResponseDto';
import useTranslate from '../../../Hook/useTranslate';
import * as apiService from '../../../Service/ApiService';

const { Option } = Select;

const UserCreate = (): JSX.Element => {
	const { translate } = useTranslate();
	const navigate = useNavigate();
	const [userCreateForm] = Form.useForm();
	const [notificationApi, notificationApiContextHolder] = notification.useNotification();

	const [isFetchingTeams, setIsFetchingTeams] = useState<boolean>(false);
	const [isUploading, setIsUploading] = useState<boolean>(false);
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

	const [teams, setTeams] = useState<TeamListDto[]>([]);
	const [teamRoles, setTeamRoles] = useState<{ teamUuid: string; roles: { uuid: string; name: string }[] }[]>([]);

	//const [fileList, setFileList] = useState<any[]>([]);
	const [imageUrl, setImageUrl] = useState<string | null>(null);
	const [imageUuid, setImageUuid] = useState<string | null>(null);

	useEffect(() => {
		const getTeamsList = async (): Promise<void> => {
			setIsFetchingTeams(true);

			try {
				const result = await apiService.makeRequest<GetTeamsListResponseDto>({
					route: 'team/list',
					method: 'POST',
				});

				const teamRolesList = [];

				for (const team of result.teams) {
					teamRolesList.push({
						teamUuid: team.uuid,
						roles: team.teamRoles,
					});
				}

				setTeams(result.teams);
				setTeamRoles(teamRolesList);
			} catch (error) {
				notificationApi.error({
					message: translate('error'),
					description: `${error}`,
				});

				console.error(error);
			} finally {
				setIsFetchingTeams(false);
			}
		};

		getTeamsList();
	}, []);

	const submit = async (values: any): Promise<void> => {
		setIsSubmitting(true);

		try {
			await apiService.makeRequest({
				route: 'user/create',
				method: 'POST',
				data: {
					lastname: values.lastname,
					firstname: values.firstname,
					password: values.password,
					globalRole: values.globalRole,
					email: values.email,
					phone: values.phone,
					visibleOnWeb: values.visibleOnWeb,
					teams: values.teams,
					description: values.description,
					imageUuid: imageUuid,
				},
			});

			message.success(translate('user.create.success'));

			navigate('/admin/user/manage');
		} catch (error) {
			notificationApi.error({
				message: translate('error'),
				description: `${error}`,
			});

			console.error(error);
		} finally {
			setIsSubmitting(false);
		}
	};

	const addTeam = (): void => {
		userCreateForm.setFieldsValue({
			teams: [...userCreateForm.getFieldValue('teams'), { teamUuid: undefined, teamRoleUuid: undefined }],
		});
	};

	const removeTeam = (key: number): void => {
		const teams = userCreateForm.getFieldValue('teams');

		if (teams.length === 1) return;

		teams.splice(key, 1);

		userCreateForm.setFieldsValue({
			teams: [...teams],
		});
	};

	const handleTeamChange = (key: number): void => {
		const currentValues = userCreateForm.getFieldsValue();
		currentValues.teams[key].teamRoleUuid = undefined;
		userCreateForm.setFieldsValue(currentValues);
	};

	const renderRoleOptions = (teamUuid: string): JSX.Element[] => {
		const currentTeamRoles = teamRoles.find((teamRole) => teamRole.teamUuid === teamUuid);

		return currentTeamRoles
			? currentTeamRoles.roles.map((teamRole) => (
					<Option key={teamRole.uuid} value={teamRole.uuid}>
						{teamRole.name}
					</Option>
			  ))
			: [];
	};

	type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

	const beforeUpload = (file: FileType): boolean => {
		const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

		if (!isJpgOrPng) {
			message.error(translate('user.create.image_restriction'));
			return;
		}

		return isJpgOrPng;
	};

	const handleFileChange = (info: any): void => {
		const file = info.file.originFileObj as FileType;

		const reader = new FileReader();

		reader.addEventListener('load', async () => {
			await uploadImage(reader.result as string);
			setImageUrl(reader.result as string);
		});

		reader.readAsDataURL(file);
	};

	const uploadImage = async (base64: string): Promise<void> => {
		setIsUploading(true);

		try {
			const result = await apiService.makeRequest<UploadImageResponseDto>({
				route: 'image/upload',
				method: 'POST',
				data: {
					base64,
				},
			});

			setImageUuid(result.image.uuid);
		} catch (error) {
			notificationApi.error({
				message: translate('error'),
				description: `${error}`,
			});

			console.error(error);
		} finally {
			setIsUploading(false);
		}
	};

	return (
		<React.Fragment>
			{notificationApiContextHolder}

			<Spin spinning={isSubmitting} fullscreen />

			<Main>
				<Head title={translate('user.create.title')} subtitle={translate('user.create.subtitle')} marginBottom='64px' />

				<Form
					form={userCreateForm}
					onFinish={submit}
					initialValues={{
						globalRole: 'user',
						visibleOnWeb: 0,
						teams: [{ teamUuid: undefined, teamRoleUuid: undefined }],
					}}
					layout='vertical'
					className='flex flex-col justify-between h-full'
				>
					<Spin spinning={isFetchingTeams || isUploading}>
						<div className='flex flex-col'>
							<div className='flex flex-col gap-[20px]'>
								<div className='flex flex-col 650:flex-row gap-[20px]'>
									<Form.Item
										name='lastname'
										label={translate('user.create.lastname')}
										required
										className='w-full 650:w-1/2'
									>
										<Input
											suffix={<Icon icon='faUser' color='#A1A1AA' />}
											size='large'
											type='text'
											autoComplete='off'
										/>
									</Form.Item>

									<Form.Item
										name='firstname'
										label={translate('user.create.firstname')}
										required
										className='w-full 650:w-1/2'
									>
										<Input
											suffix={<Icon icon='faUser' color='#A1A1AA' />}
											size='large'
											type='text'
											autoComplete='off'
										/>
									</Form.Item>
								</div>

								<div className='flex gap-[20px]'>
									<Form.Item
										name='password'
										label={translate('user.create.password')}
										required
										className='w-full 650:w-1/2'
									>
										<Input.Password size='large' type='password' autoComplete='off' />
									</Form.Item>

									<Form.Item
										name='globalRole'
										label={translate('user.create.global_role')}
										required
										className='w-full 650:w-1/2'
									>
										<Select size='large'>
											<Option value='admin'>{translate('user.global_role.admin')}</Option>
											<Option value='user'>{translate('user.global_role.user')}</Option>
											{/* <Option value='external'>{translate('user.global_role.external')}</Option> */}
										</Select>
									</Form.Item>
								</div>

								<div className='flex gap-[20px]'>
									<Form.Item name='phone' label={translate('user.create.phone')} className='w-full 650:w-1/2'>
										<Input
											suffix={<Icon icon='faPhone' color='#A1A1AA' />}
											size='large'
											type='text'
											autoComplete='off'
										/>
									</Form.Item>

									<Form.Item
										name='visibleOnWeb'
										label={translate('user.create.visible_on_web')}
										required
										className='w-full 650:w-1/2'
									>
										<Select size='large'>
											<Option value={1}>{translate('user.create.visible_on_web.yes')}</Option>
											<Option value={0}>{translate('user.create.visible_on_web.no')}</Option>
										</Select>
									</Form.Item>
								</div>

								<Form.Item name='email' label={translate('user.create.email')} required className='w-full'>
									<Input
										suffix={<Icon icon='faEnvelope' color='#A1A1AA' />}
										size='large'
										type='text'
										autoComplete='off'
									/>
								</Form.Item>
							</div>

							<Divider orientation='left'>
								<span className='text-lg font-medium'>{translate('user.create.team_assign')}</span>
							</Divider>

							<div className='flex flex-col gap-5'>
								<Form.List name='teams'>
									{(fields): JSX.Element => (
										<React.Fragment>
											{fields.map(({ key, name, ...restField }) => {
												const formTeams = userCreateForm.getFieldValue('teams');
												const currentTeam = formTeams[key];

												return (
													<div key={`create-user-team-${key}`} className='flex items-center gap-[20px]'>
														<Form.Item {...restField} name={[name, 'teamUuid']} className='w-full'>
															<Select
																onChange={(): void => handleTeamChange(key)}
																placeholder={translate('user.create.add_team.team')}
																showSearch
																allowClear
																optionFilterProp='children'
																size='large'
																className='w-full'
															>
																{teams.map((team) => (
																	<Option key={team.uuid} value={team.uuid}>
																		{team.name}
																	</Option>
																))}
															</Select>
														</Form.Item>

														<Form.Item {...restField} name={[name, 'teamRoleUuid']} className='w-full'>
															<Select
																disabled={!currentTeam.teamUuid}
																placeholder={translate('user.create.add_team.role')}
																showSearch
																allowClear
																optionFilterProp='children'
																size='large'
																className='w-full'
															>
																{renderRoleOptions(currentTeam.teamUuid)}
															</Select>
														</Form.Item>

														<Button danger type='text' onClick={(): void => removeTeam(key)}>
															<Icon icon='faTrash' color='#F31260' />
														</Button>
													</div>
												);
											})}

											<Form.Item>
												<Button type='dashed' onClick={(): void => addTeam()} block icon={<Icon icon='faPlus' />}>
													{translate('user.create.add_team')}
												</Button>
											</Form.Item>
										</React.Fragment>
									)}
								</Form.List>
							</div>
						</div>

						<Divider orientation='left'>
							<span className='text-lg font-medium'>{translate('user.create.others')}</span>
						</Divider>

						<div className='flex items-center gap-5 mb-10'>
							<Form.Item label className='flex items-center'>
								<Upload
									listType='picture-card'
									showUploadList={false}
									customRequest={(): void => undefined}
									beforeUpload={beforeUpload}
									onChange={handleFileChange}
								>
									{imageUrl ? (
										<img src={imageUrl} alt='avatar' className='w-full' />
									) : (
										<button type='button' className='bg-transparent border-none'>
											<div>{translate('user.create.image')}</div>
										</button>
									)}
								</Upload>
							</Form.Item>

							<Form.Item name='description' label={translate('user.create.description')} className='w-full'>
								<Input.TextArea rows={4} />
							</Form.Item>
						</div>
					</Spin>

					<Form.Item className='flex justify-end mt-10'>
						<Button
							disabled={isSubmitting || isFetchingTeams || isUploading}
							size='large'
							block
							type='primary'
							htmlType='submit'
							className='px-[40px]'
						>
							{translate('user.create.save')}
						</Button>
					</Form.Item>
				</Form>
			</Main>
		</React.Fragment>
	);
};

export default UserCreate;
