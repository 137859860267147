export const Theme = {
	token: {
		colorPrimary: '#5078FD',
		colorInfo: '#5078FD',
		colorSuccess: '#3AB795',
		colorError: '#F31260',
		colorTextBase: '#1B2337',
		borderRadius: 12,
	},
	components: {
		Alert: {
			colorErrorBorder: 'rgb(255, 240, 240)',
			colorSuccessBorder: 'rgb(233, 247, 241)',
			colorWarningBorder: 'rgb(255, 251, 230)',
		},
		Cascader: {
			colorHighlight: '#7c93dc',
		},
		Collapse: {
			colorBorder: '#F4F4F5',
			headerBg: '#F4F4F5',
			contentPadding: '0px 0px',
		},
		DatePicker: {
			colorBgContainer: '#FAFAFA',
			colorBorder: '#E4E4E7',
			colorBgContainerDisabled: '#E4E4E7',
			colorTextDisabled: '#A1A1AA',
		},
		Descriptions: {
			colorSplit: '#F4F4F5',
		},
		Divider: {
			colorSplit: 'rgb(228, 228, 231)',
		},
		Form: {
			margin: 0,
			marginLG: 0,
			paddingSM: 0,
			marginXXS: 0,
			verticalLabelPadding: '0 0 4px',
		},
		Input: {
			colorBgContainer: '#FAFAFA',
			colorBorder: '#E4E4E7',
			colorBgContainerDisabled: '#E4E4E7',
			colorTextDisabled: '#A1A1AA',
		},
		InputNumber: {
			colorBgContainer: '#FAFAFA',
			colorBorder: '#E4E4E7',
			colorBgContainerDisabled: '#E4E4E7',
			colorTextDisabled: '#A1A1AA',
		},
		Menu: {
			itemBg: 'transparent',
			itemSelectedColor: '#718FEF',
			itemSelectedBg: 'transparent',
			subMenuItemBg: 'transparent',
		},
		Segmented: {
			borderRadius: 6,
			borderRadiusLG: 6,
			borderRadiusSM: 6,
			borderRadiusXS: 6,
		},
		Select: {
			colorBgContainer: '#FAFAFA',
			colorBorder: '#E4E4E7',
			colorBgContainerDisabled: '#E4E4E7',
			colorTextDisabled: '#A1A1AA',
		},
		Slider: {
			colorPrimaryBorderHover: '#7c93dc',
		},
		Space: {
			padding: 8,
			paddingXS: 4,
			paddingLG: 16,
		},
		Table: {
			headerBg: 'rgb(244, 244, 245)',
			borderColor: 'rgb(228, 228, 231)',
			controlItemBgHover: 'rgb(250, 250, 250)',
		},
		Tag: {
			borderRadiusSM: 12,
			marginXS: 0,
		},
		Timeline: {
			dotBg: 'transparent',
		},
		Upload: {
			margin: 0,
			marginXL: 0,
			marginXS: 0,
			marginXXS: 0,
		},
	},
};
